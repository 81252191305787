import * as React from 'react';
import Layout from '../templates/Layout';
import { TemplateItems } from 'gogoo-common/feed/feed-items';
import styled from 'styled-components';
import { Organisation } from 'gogoo-common/content/Organisation';
import { OrganisationDashboardResult } from 'gogoo-common/admin/Organisation';
import Input from '../atoms/Input';
import HTMLEditor from '../atoms/HtmlEditor';
import { colors } from '../../globals/colors';
import SearchVenueList from './SearchVenueList';

const UICardWrapper = styled.div`
    background-color: ${colors.white};
    color: ${colors.main};
    padding: 10px 20px;
    margin-bottom: 12px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
`;


type EditorProps = { data: OrganisationDashboardResult, setOrganisation(val: Organisation): Promise<Organisation> }


export const AddElementButton: React.FC<{ onClick?: () => void, label: string }> = ({ onClick, label }) => (
    <div style={{ padding: "3px 10px", color: colors.main, background: colors.white, cursor: 'pointer' }} onClick={onClick}>
        + {label}
    </div>
)


export const AddElementList: React.FC<{} & EditorProps> = ({ data, setOrganisation }) => {

    const addItem = (templateItem: TemplateItems) => {
        setOrganisation({ ...data.organisation, template: [...data.organisation.template, templateItem] })
    }

    const AddBlogElement: React.FC = () => {
        const [value, setValue] = React.useState<string>()
        const selected = !!value && data.blogPosts.find((i) => i.id === value)

        const [started, setStarted] = React.useState(false)


        if (started) return (
            <>
                <span style={{ marginBottom: 3 }}>Vælg en</span>
                <Input.Select
                    onSelect={(selected) => setValue(selected.key.toString())}
                    selected={!!selected ? { key: selected.id, displayName: selected.name } : undefined}
                    items={data.blogPosts.map((i) => ({ key: i.id, displayName: i.name }))} />
                <button disabled={!value} onClick={() => addItem({ type: "BlogpostItem", id: value })}>Tilføj element</button>
            </>
        )

        return <AddElementButton onClick={() => setStarted(true)} label="Blog element" />
    }

    const FeedElement: React.FC<{ feedType: "SmallEventListTemplate" | "DateTabbedEventListTemplate" | "CategoryTabbedEventListTemplate", label: string }> = ({ feedType, label }) => {
        const [value, setValue] = React.useState<string>()
        const selected = !!value && data.organisation.feeds.find((i) => i.feedId === value)

        const [started, setStarted] = React.useState(false)


        if (started) return (
            <>
                <span style={{ marginBottom: 3 }}>Vælg en</span>
                <Input.Select
                    onSelect={(selected) => setValue(selected.key.toString())}
                    selected={!!selected ? { key: selected.feedId, displayName: selected.feedName } : undefined}
                    items={data.organisation.feeds.map((i) => ({ key: i.feedId, displayName: i.feedName + " (" + i.feedId + ")" }))} />
                <button disabled={!value} onClick={() => value && addItem({ type: feedType, id: value })}>Tilføj element</button>
            </>
        )

        return <AddElementButton onClick={() => setStarted(true)} label={label} />
    }

    const AddVenueElement: React.FC<{ feedType: 'VenueInfoItem', label: string }> = ({ feedType, label }) => {
        const [started, setStarted] = React.useState(false)


        if (started) return (
            <>
                <span style={{ marginBottom: 3 }}>Vælg en</span>
                <SearchVenueList excludedVenues={[]} onVenueClick={(id, name) => {
                    addItem({ type: feedType, venueId: id, title: name })
                }} onClickLabel={'Tilføj element'} />
            </>
        )

        return <AddElementButton onClick={() => setStarted(true)} label={label} />
    }


    return (
        <Layout.VerticalFlex style={{ gap: 12 }}>
            <AddElementButton label="HTML element" onClick={() => addItem({ type: "HTMLElmentTemplate", data: "" })} />
            <AddElementButton label="Title element" onClick={() => addItem({ type: "TitleTemplate", data: "" })} />
            <AddBlogElement />
            <FeedElement feedType='SmallEventListTemplate' label='Alm event liste' />
            <FeedElement feedType="DateTabbedEventListTemplate" label='Datobaseret event liste' />
            <FeedElement feedType="CategoryTabbedEventListTemplate" label='Kategoribaseret event liste' />

            <AddVenueElement feedType='VenueInfoItem' label='Udbyder information' />

        </Layout.VerticalFlex>
    )

}

export const TemplateItemControls: React.FC<{
    deleteConfirmation: boolean, setDeleteConfirmation(val: boolean): void, deleteItem(): void,
    index: number, length: number, move(value: number): void
}> = ({ deleteConfirmation, setDeleteConfirmation, deleteItem, move, index, length }) => (
    <Layout.HorizontalFlexAlignCenter style={{ gap: 6 }}>
        {deleteConfirmation && <span style={{ backgroundColor: colors.white, color: colors.main }}>Er du sikker?</span>}
        <button onClick={() => deleteConfirmation ? deleteItem() : setDeleteConfirmation(true)} style={{ backgroundColor: colors.danger, padding: "4px 8px" }}>{deleteConfirmation ? "Ja slet elementet" : "Slet"}</button>
        {deleteConfirmation && <button style={{ padding: "4px 8px" }} onClick={() => setDeleteConfirmation(false)}>Annuller</button>}
        {!deleteConfirmation && length > 1 && index !== 0 && <button onClick={() => move(-1)} style={{ padding: "4px 8px" }}>Ryk op</button>}
        {!deleteConfirmation && index !== (length - 1) && length > 1 && <button onClick={() => move(1)} style={{ padding: "4px 8px" }}>Ryk ned</button>}
    </Layout.HorizontalFlexAlignCenter>
)

export const TemplateItem: React.FC<{ templateItem: TemplateItems, index: number } & EditorProps> = ({ templateItem, data, index, setOrganisation }) => {
    const { organisation, blogPosts } = data

    type UICardProps = { onPres(): void, hasChanged: boolean, label: string } & React.PropsWithChildren
    const UICard: React.FC<UICardProps> = ({ children, onPres, hasChanged, label }) => {
        const [deleteConfirmation, setDeleteConfirmation] = React.useState(false)
        const move = (val: number) => {
            const newList = data.organisation.template
            const position = data.organisation.template[index + val]
            const current = data.organisation.template[index]
            newList[index + val] = current
            newList[index] = position
            setOrganisation({ ...data.organisation, template: newList })
        }

        const deleteItem = () => {
            const newList = data.organisation.template
            newList.splice(index, 1);
            setOrganisation({ ...data.organisation, template: newList })
        }

        return (
            <UICardWrapper>
                <Layout.HorizontalFlexAlignCenter style={{ justifyContent: 'space-between' }}>
                    <p><b style={{ color: colors.main }}>{label}</b></p>
                    <TemplateItemControls deleteConfirmation={deleteConfirmation} setDeleteConfirmation={setDeleteConfirmation} move={move}
                        index={index} length={data.organisation.template.length} deleteItem={deleteItem} />
                </Layout.HorizontalFlexAlignCenter>
                {children}
                <button disabled={!hasChanged} style={{ marginTop: 12 }} onClick={onPres}>Gem ændringer</button>
            </UICardWrapper>)
    }


    if (templateItem.type === "BlogpostItem") {
        const Item: React.FC = () => {
            const [value, setValue] = React.useState(blogPosts.find((i) => i.id === templateItem.id))

            function onSubmit() {
                if (value) {
                    const newTemplateItem = data.organisation.template
                    newTemplateItem[index] = { type: "BlogpostItem", id: value.id }
                    setOrganisation({ ...data.organisation, template: newTemplateItem })
                }
            }
            return (
                <UICard onPres={onSubmit} hasChanged={value?.id !== templateItem.id} label='Blog Opslags element'>
                    {!value && <p>Kan ikke finde blog post med id: templateItem.id</p>}
                    {value && <Layout.VerticalFlex>
                        <span style={{ marginBottom: 3 }}>Vælg en</span>
                        <Input.Select onSelect={(selected) => setValue(blogPosts.find((i) => i.id === selected.key))}
                            selected={{ displayName: value.name, key: value.id }}
                            items={blogPosts.map((i) => ({ key: i.id, displayName: i.name }))} />
                    </Layout.VerticalFlex>}
                </UICard>)

        }
        return <Item />
    }

    if (templateItem.type === "HTMLElmentTemplate") {
        const Item: React.FC = () => {
            const [value, setValue] = React.useState(templateItem.data)

            function onSubmit() {
                const newTemplateItem = data.organisation.template
                newTemplateItem[index] = { type: "HTMLElmentTemplate", data: value }
                setOrganisation({ ...data.organisation, template: newTemplateItem })
            }

            return (
                <UICard onPres={onSubmit} hasChanged={value !== templateItem.data} label='HTML Element'>
                    <HTMLEditor hideKTNInput onChange={setValue} value={value} />
                </UICard>
            )
        }
        return <Item />
    }

    if (templateItem.type === "SmallEventListTemplate" || templateItem.type === "DateTabbedEventListTemplate" || templateItem.type === "CategoryTabbedEventListTemplate") {
        const localType: "SmallEventListTemplate" | "DateTabbedEventListTemplate" | "CategoryTabbedEventListTemplate" = templateItem.type
        let label = "Ukendt label"
        if (localType === "SmallEventListTemplate") label = "Alm event liste"
        if (localType === "DateTabbedEventListTemplate") label = "Datobaseret event liste"
        if (localType === "CategoryTabbedEventListTemplate") label = "Kategoribaseret event list"


        const Item: React.FC = () => {
            const [value, setValue] = React.useState(organisation.feeds.find((i) => i.feedId === templateItem.id))

            function onSubmit() {
                if (value) {
                    const newTemplateItem = data.organisation.template
                    newTemplateItem[index] = { type: localType, id: value.feedId }
                    setOrganisation({ ...data.organisation, template: newTemplateItem })
                }
            }
            return (
                <UICard onPres={onSubmit} hasChanged={value?.feedId !== templateItem.id} label={label}>
                    {value && <Layout.VerticalFlex>
                        <span style={{ marginBottom: 3 }}>Vælg en</span>
                        <Input.Select onSelect={(selected) => setValue(organisation.feeds.find((i) => i.feedId === selected.key))}
                            selected={{ displayName: value.feedName, key: value.feedId }}
                            items={organisation.feeds.map((i) => ({ key: i.feedId, displayName: i.feedName }))} />
                    </Layout.VerticalFlex>}
                    {!value && <p>Der skere en fejl. Kan ikke finde feed med id: {templateItem.id}</p>}
                </UICard>
            )
        }
        return <Item />
    }

    if (templateItem.type === "TitleTemplate") {
        const Item: React.FC = () => {
            const [value, setValue] = React.useState(templateItem.data)

            function onSubmit() {
                const newTemplateItem = data.organisation.template
                newTemplateItem[index] = { type: "TitleTemplate", data: value }
                setOrganisation({ ...data.organisation, template: newTemplateItem })
            }

            return (
                <UICard onPres={onSubmit} hasChanged={value !== templateItem.data} label='Overskrifts element'>
                    <input defaultValue={value} onChange={(e) => setValue(e.target.value)} />
                </UICard>
            )
        }
        return <Item />
    }

    if (templateItem.type === "VenueInfoItem") {
        const Item: React.FC = () => {
            const [excludeMap, setExcludeMap] = React.useState(!!templateItem.excludeMap)

            function onSubmit() {
                const newTemplateItem = data.organisation.template
                const item = newTemplateItem[index]
                if (item.type === 'VenueInfoItem') {
                    newTemplateItem[index] = { type: 'VenueInfoItem', title: item.title, venueId: item.venueId, id: item.id, excludeMap }
                    setOrganisation({ ...data.organisation, template: newTemplateItem })
                }

            }

            return (
                <UICard onPres={onSubmit} hasChanged={excludeMap !== !!templateItem.excludeMap} label='Udbyder information'>
                    <span><b>{templateItem.title}</b></span>
                    <span>Id: {templateItem.venueId}</span>
                    <span><i style={{ fontSize: 10 }}>(Kan kun ændres ved at slette og genindlæse)</i></span>

                    <Layout.HorizontalFlexAlignCenter style={{ gap: 12, marginTop: 12, marginBottom: 12 }}>
                        <span>Skjul kort</span>
                        <Input.TogleSwitch value={excludeMap} onCLick={() => setExcludeMap(!excludeMap)} />
                    </Layout.HorizontalFlexAlignCenter>
                </UICard>
            )
        }
        return <Item />
    }


    return (
        <UICardWrapper>
            <span style={{ color: organisation.colorScheme.textColor }}>UKENDT TYPE: {templateItem.type}</span>
        </UICardWrapper>
    )
}



export const UIEditor: React.FC<{ templateItems: TemplateItems[] } & EditorProps> = ({ templateItems, data, setOrganisation }) => {

    return (
        <Layout.VerticalFlex style={{ flex: 1, gap: 12, paddingBottom: 100 }}>
            {templateItems.map((item, key) => <TemplateItem index={key} setOrganisation={setOrganisation} key={key} data={data} templateItem={item} />)}
            {templateItems.length === 0 && <span>Ingen elementer tilføjet</span>}
        </Layout.VerticalFlex>

    )
}
