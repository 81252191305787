import * as React from 'react';
import { useParams } from 'react-router-dom';
import { LoadingScreen } from '../../components/atoms/Defaults';
import { Navigation } from '../../components/organisms/Navigation';
import Layout from '../../components/templates/Layout';
import { Loading, LoadingState } from '../../globals/states';
import apiHooksV2 from '../../globals/api-hooks-v2';
import { Organisation } from 'gogoo-common/content/Organisation';
import { AddElementList, UIEditor } from '../../components/molecules/UIEditor';
import { OrganisationDashboardResult } from 'gogoo-common/admin/Organisation';
import { BreadCrumb } from '../../components/molecules/BreadCrumb';
import Spinner from '../../components/atoms/LoadingSpinner';
import { ErrorScreenTemplate } from '../../components/templates/ErrorScreen';


export const EditOrganizationPage: React.FC = () => {
  const { organisationId } = useParams<{ organisationId: string }>()
  const [dashboard, setDashboard] = React.useState<OrganisationDashboardResult>()

  const [loading, setLoading] = React.useState<LoadingState>()
  const [updating, setUpdating] = React.useState<boolean>()


  const init = React.useCallback(async () => {
    setLoading({ loading: Loading.LOADING })
    const result = organisationId && await apiHooksV2.getOrgansationsDashboard(organisationId)
    if (result) {
      setLoading({ loading: Loading.COMPLETE })
      setDashboard(result)
    } else {
      setLoading({ loading: Loading.ERROR, errorMessage: "Kunne ikke finde organisationen med id: " + organisationId + " eller du har ikke adgang" })
    }
  }, [organisationId])

  React.useEffect(() => { init() }, [init])

  const handleUpdates = async (updated: Organisation) => {
    setUpdating(true)
    const result = await apiHooksV2.updateOrganisation(updated)
    setUpdating(false)
    if (!result) setLoading({ loading: Loading.ERROR, errorMessage: "Du har ikke adgang til at ændre indholdet" })
    return updated
  }

  if (!loading || loading.loading === Loading.LOADING) return <LoadingScreen />
  if (loading.loading === Loading.ERROR) return <ErrorScreenTemplate><p>Error: {loading.errorMessage}</p></ErrorScreenTemplate>
  if (!dashboard) return <ErrorScreenTemplate><p>Error: Contact IT Admin</p></ErrorScreenTemplate>
  const { organisation } = dashboard

  const onOrganisationChange = async (org: Organisation) => {
    setDashboard((old) => old && ({ ...old, organisation: org }))
    const updates = {
      organizationId: org.organizationId,
      name: org.name,
      active: org.active,
      template: org.template,
      colorScheme: org.colorScheme,
      feeds: org.feeds,
      data: undefined
    }
    return await handleUpdates(updates)
  }

  return (
    <Layout.Body style={{ paddingBottom: 0 }}>
      <Navigation title={organisation && organisation.name} />
      <Layout.Container>
        <BreadCrumb links={[
          { label: "Organisationer", url: "/organisations" },
          { label: organisation && organisation.name, url: "/organisations/" + (organisation && organisation.organizationId) },
          { label: "Rediger " + (organisation && organisation.name), url: "/organisations/" + (organisation && organisation.organizationId) + "/edit" },
        ]} />
        <h1>Organisations visning</h1>
        <p>Her kan du redigere og ændre i din organisations visning på gogo appen.</p>
        <Layout.HorizontalFlex style={{ flexGrow: 4 }}>
          <Layout.VerticalFlex style={{ flex: 1, paddingRight: 32 }}>
            <h3>Farver</h3>
            <Layout.HorizontalFlexAlignCenter style={{ justifyContent: 'space-between' }}>
              <span>Baggrundsfarve: </span>
              <Layout.HorizontalFlexAlignCenter>
                <span>{organisation.colorScheme.backgroundColor}</span>
                <div style={{ marginLeft: 12, marginRight: 12, backgroundColor: organisation.colorScheme.backgroundColor, width: 20, height: 20 }}></div>
              </Layout.HorizontalFlexAlignCenter>
            </Layout.HorizontalFlexAlignCenter>
            <Layout.HorizontalFlexAlignCenter style={{ justifyContent: 'space-between', marginTop: 12 }}>
              <span>Tekst farve:</span>
              <Layout.HorizontalFlexAlignCenter>
                <span>{organisation.colorScheme.textColor}</span>
                <div style={{ marginLeft: 12, marginRight: 12, backgroundColor: organisation.colorScheme.textColor, width: 20, height: 20 }}></div>
              </Layout.HorizontalFlexAlignCenter>
            </Layout.HorizontalFlexAlignCenter>
            <h3>Tilføj komponenter</h3>
            <AddElementList data={dashboard} setOrganisation={onOrganisationChange} />
          </Layout.VerticalFlex>
          <Layout.VerticalFlex style={{ flex: 3 }}>
            <h3>Forhåndsvisning</h3>
            <Layout.VerticalFlex style={{}}>
              {updating && <Layout.VerticalFlex style={{
                position: 'fixed', flex: 1, width: 100, height: 100, backgroundColor: '#ffffffb2', zIndex: 100,
                margin: 'auto', top: 0, left: 0, right: 0, bottom: 0, borderRadius: 4,
                justifyContent: 'center', alignItems: 'center', paddingLeft: 20, paddingTop: 20
              }}>
                <Spinner />
              </Layout.VerticalFlex>}
              <UIEditor templateItems={organisation.template} data={dashboard} setOrganisation={onOrganisationChange} />
            </Layout.VerticalFlex>
          </Layout.VerticalFlex>
        </Layout.HorizontalFlex>
      </Layout.Container>
    </Layout.Body>
  );
}