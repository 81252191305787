import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingScreen } from '../../components/atoms/Defaults';
import { Navigation } from '../../components/organisms/Navigation';
import Layout from '../../components/templates/Layout';
import { Loading, LoadingState } from '../../globals/states';
import apiHooksV2 from '../../globals/api-hooks-v2';
import { BreadCrumb } from '../../components/molecules/BreadCrumb';
import { BlogPostEditor } from '../../components/organisms/EditBlogPost';
import { CreateBlogPost } from 'gogoo-common/admin/BlogPost';
import { BlogPost } from 'gogoo-common/content/BlogPost';
import { ErrorScreenTemplate } from '../../components/templates/ErrorScreen';


export const BlogPage: React.FC<{ create?: boolean }> = ({ create }) => {
    const navigate = useNavigate()
    const { blogId } = useParams<{ blogId?: string }>()
    const [loading, setLoading] = React.useState<LoadingState>()
    const [blogPost, setBlogPost] = React.useState<BlogPost>()

    const init = React.useCallback(async () => {
        if (create) {
            setLoading({ loading: Loading.COMPLETE })
            return
        }
        setLoading({ loading: Loading.LOADING })
        const result = blogId && await apiHooksV2.getBlogPostById(blogId)
        if (result) {
            setBlogPost(result)
            setLoading({ loading: Loading.COMPLETE })
        } else {
            setLoading({ loading: Loading.ERROR, errorMessage: "Kunne ikke finde blog med id: " + blogId })
        }
    }, [blogId])

    const onCreate = async (create: CreateBlogPost) => {
        if(loading?.loading === Loading.LOADING) return
        const result = await apiHooksV2.createBlogPost(create)
        if (result) {
            navigate("/blogs/" + result.id)
        } else {
            setLoading({ loading: Loading.ERROR, errorMessage: "Der skete en fejl i kommunikationene til serveren" })
        }
    }

    const onUpdate = async (updates: BlogPost) => {
        const result = await apiHooksV2.updateBlogPost(updates)
        if (result) {
            navigate("/blogs")
            setLoading({ loading: Loading.COMPLETE })
        } else {
            setLoading({ loading: Loading.ERROR, errorMessage: "Der skete en fejl i kommunikationene til serveren" })
        }
    }

    const onDelete = async (id: string) => {
        const result = await apiHooksV2.deleteBlogPost(id)
        if (result) {
            navigate("/blogs")
            setLoading({ loading: Loading.COMPLETE })
        } else {
            setLoading({ loading: Loading.ERROR, errorMessage: "Blog opslaget blev ikke slettet" })
        }
    }

    React.useEffect(() => { init() }, [init])

    if (!loading || loading.loading === Loading.LOADING) return <LoadingScreen />
    if (loading.loading === Loading.ERROR) return <ErrorScreenTemplate>{loading.errorMessage}</ErrorScreenTemplate>


    return (
        <Layout.Body>
            <Navigation title={blogPost?.name} />
            <Layout.Container>
                <Layout.HorizontalFlex style={{ justifyContent: 'space-between', marginBottom: 24, alignItems: 'center' }}>
                    <BreadCrumb links={[
                        { label: "Blog", url: "/blogs" },
                        { label: create ? 'Opret nyt' : blogPost?.name || '', url: create ? "/blogs/create" : "/blogs/" + blogPost?.id },
                    ]} />
                </Layout.HorizontalFlex>
            </Layout.Container>
            <Layout.Container>
                {!create && <BlogPostEditor initial={blogPost} onUpdate={onUpdate} onDelete={onDelete} />}
                {create && <BlogPostEditor onCreate={onCreate} />}
            </Layout.Container>
        </Layout.Body>
    )
}