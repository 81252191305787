import * as React from 'react';
import { useParams } from 'react-router-dom';
import { LoadingScreen } from '../../components/atoms/Defaults';
import { Navigation } from '../../components/organisms/Navigation';
import Layout from '../../components/templates/Layout';
import { colors } from '../../globals/colors';
import { Loading, LoadingState } from '../../globals/states';
import HTMLEditor from '../../components/atoms/HtmlEditor';
import CustomTable from '../../components/molecules/CustomTable';
import { Tooltip } from '../../components/atoms/Tooltip';
import { AdminUser } from '../../shared/Admin';
import Attention from '../../assets/attention.svg';
import Error from '../../assets/error.svg';
import Info from '../../assets/info.svg';
import Success from '../../assets/success.svg';
import Spinner from '../../components/atoms/LoadingSpinner';
import { KultunautEvent, KultunautVenue, PremiumVenueMetaData } from '../../../../gogoo-common/content/Kultunaut'
import { StatusComponent } from '../../components/atoms/StatusComponent';
import apiHooksV2 from '../../globals/api-hooks-v2';
import { kultuanutDateToNodeDate, nodeDateToKTNDate } from '../../globals/helpers';
import EventMetaData, { LastChanceTicketData } from 'gogoo-common/content/EventMetaData';
import { ReactComponent as NewWindowIcon } from '../../assets/new-window.svg'
import { DataBox } from '../../components/molecules/DataElements';
import { IsGogooAdmin } from '../../globals/global-autorisations';
import { BreadCrumb } from '../../components/molecules/BreadCrumb';
import { ErrorScreenTemplate } from '../../components/templates/ErrorScreen';
import Input from '../../components/atoms/Input';

interface EventMetaDataWithLastChance extends EventMetaData {
  ticket: LastChanceTicketData
}

interface KultunautEventWithLastChance extends KultunautEvent {
  EventMetaData: EventMetaDataWithLastChance
}


const AccessItem: React.FC<{ item: AdminUser, venueId: number }> = ({ item, venueId }) => {
  const [loading, setLoading] = React.useState(false)
  const [success, setSuccess] = React.useState(false)

  const handleClick = async () => {
    setLoading(true)
    const result = await apiHooksV2.approveVenueAccess(venueId, item.userId)
    setSuccess(!!result)
    setLoading(false)
  }

  return (
    <Layout.HorizontalFlex style={{ justifyContent: "space-between", borderBottom: "1px #d9d9d9 solid", paddingTop: 4, paddingBottom: 4 }}>
      {loading ? <span>Loading</span> : success ?
        <span>Adgang givet</span> :
        <>
          <span>{item.displayName}</span>
          <p style={{ textDecoration: 'underline' }} onClick={handleClick}>Giv adgang</p>
        </>}
    </Layout.HorizontalFlex>
  )
}


enum TicketStatus { EventHasPassed, EventIsSoldOut, EventIsNotLive, EventIsLive }

const getTicketStatus = (data: KultunautEventWithLastChance) => {
  const currentDate = new Date();
  const publishDate = data.EventMetaData.ticket.saleUCTStartDate && new Date(data.EventMetaData.ticket.saleUCTStartDate)
  const soldOutDate = data.EventMetaData.ticket.salesUCTEndDate && new Date(data.EventMetaData.ticket.salesUCTEndDate)
  const eventDate = kultuanutDateToNodeDate(data.Startdate, data.Starttime)

  if (eventDate && eventDate.getTime() < currentDate.getTime()) return TicketStatus.EventHasPassed
  else if (soldOutDate && soldOutDate.getTime() < currentDate.getTime()) return TicketStatus.EventIsSoldOut
  else if (publishDate && publishDate.getTime() > currentDate.getTime()) return TicketStatus.EventIsNotLive
  else return TicketStatus.EventIsLive

}

const RenderStatus: React.FC<{ data: KultunautEventWithLastChance }> = ({ data }) => {

  const getData = () => {
    let icon: string;
    let iconDescription = "";
    const ticketStatus = getTicketStatus(data)

    if (ticketStatus === TicketStatus.EventHasPassed) {
      icon = Error
      iconDescription = "Eventet er overstået"
    } else if (ticketStatus === TicketStatus.EventIsSoldOut) {
      icon = Attention
      iconDescription = "Eventet er udsolgt"
    } else if (ticketStatus === TicketStatus.EventIsNotLive) {
      icon = Info;
      iconDescription = "Eventet er ikke live endnu"
    } else {
      icon = Success;
      iconDescription = "Eventet er live"
    }

    return { icon, iconDescription }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "center" }}>
      <Tooltip description={getData().iconDescription} customComponent={
        <img src={getData().icon} width={30} height={30} alt={'status'} />
      } />
    </div>
  )
}

const SoldOutButton: React.FC<{ data: KultunautEventWithLastChance, refresh: () => Promise<void> }> = ({ data, refresh }) => {
  const [loadingState, setLoadingState] = React.useState(false)

  const setSoldOut = async (eventData: KultunautEventWithLastChance) => {
    setLoadingState(true)

    const metaData = eventData.EventMetaData
    metaData.ticket.salesUCTEndDate = (new Date()).toUTCString()

    const result = await apiHooksV2.addEventMetaData(metaData)
    if (result?.success) await refresh()
    setLoadingState(false)
  }

  const showSoldOutButton = getTicketStatus(data) === TicketStatus.EventIsNotLive

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "30px" }}>
      {showSoldOutButton && !loadingState && <button disabled={loadingState} onClick={() => setSoldOut(data)}>Gør udsolgt</button>}
      {!showSoldOutButton && !loadingState && <span>Udsolgt/udløbet</span>}
      {loadingState && <Spinner compact />}
    </div>)
}

const EditButton = (id: number) => <button style={{ color: colors.white, backgroundColor: colors.lightMain, borderColor: colors.background, borderWidth: 1 }} onClick={() => window.open('/events/' + id)}>Rediger <NewWindowIcon width={10} height={10} fill={colors.white} /></button>

const eventsToTable = (event: KultunautEvent[]) => [["Event title", "Dato", "Mere"], ...event.map((item) => [item.Title, item.Startdate, EditButton(item.Id)])]

const productsToTable = (event: KultunautEventWithLastChance[], refresh: () => Promise<void>) => [
  ["Event title", "Udgives", "Udsolgt dato", "Status", "Aktion", "Redigering"],
  ...event.map((item) => [
    item.Title,
    nodeDateToKTNDate(new Date(item.EventMetaData.ticket.saleUCTStartDate)),
    nodeDateToKTNDate(new Date(item.EventMetaData.ticket.salesUCTEndDate)),
    <RenderStatus data={item} />, <SoldOutButton refresh={refresh} data={item} />,
    EditButton(item.Id)
  ])]


const PremiumVenueTextInput: React.FC<{ label: string, value?: string, setValue(value: string): void }> = ({ label, value, setValue }) => {

  return (
    <Layout.VerticalFlex style={{ gap: 4 }}>
      <label>{label}</label>
      <input onChange={(e) => setValue(e.target.value)} value={value} />
    </Layout.VerticalFlex>
  )
}

const BuyPremiumButton: React.FC<{ venueId: number, reload():Promise<void> }> = ({ venueId, reload }) => {
  const [loading, setLoading] = React.useState(false)

  const buyPremium = async () => {
    setLoading(true)
    const result = await apiHooksV2.getAdminUser()
    if (IsGogooAdmin(result)) {
      const expireDate = new Date()
      expireDate.setFullYear(new Date().getFullYear() + 1)
      await apiHooksV2.updatePremiumVenueMetaData({
        "venueId": venueId,
        "htmlContent": "",
        "isValid": true,
        "premiumValidUntil": expireDate.toISOString(),
        "premiumPackage": "GogooSponsored"
      })
      await reload()
    } else {
      window.open('https://www.gogoo.app/kontakt', "_blank")
    }
    setLoading(false)
  }

  return (
    <Layout.VerticalFlex style={{ backgroundColor: colors.primary, padding: 14, justifyContent: 'center', minHeight: 160, alignItems: 'center', cursor: 'pointer' }}
      onClick={buyPremium}>{loading ? "Opdatere" : "Køb premium"}</Layout.VerticalFlex>
  )
}


export const PremiumVenueEditor: React.FC<{ premiumMetaData: PremiumVenueMetaData, reloadData(): Promise<void> }> = ({ premiumMetaData, reloadData }) => {
  const [html, setHtml] = React.useState(premiumMetaData.htmlContent)
  const [loading, setLoading] = React.useState(false)
  const [facebookLink, setFacebookLink] = React.useState(premiumMetaData.facebookLink)
  const [instagramLink, setInstagramLink] = React.useState(premiumMetaData.instagramLink)
  const [newsletterLink, setNewsletterLink] = React.useState(premiumMetaData.newsletterLink)
  const [customImage, setCustomImage] = React.useState(premiumMetaData.customImage)


  const handleClick = async () => {
    setLoading(true)
    await apiHooksV2.updatePremiumVenueMetaData({
      ...premiumMetaData,
      htmlContent: html,
      facebookLink,
      instagramLink,
      newsletterLink,
      customImage
    })
    await reloadData()
    setLoading(false)
  }



  const inputHasBeenEdited = html !== premiumMetaData.htmlContent || facebookLink !== premiumMetaData.facebookLink || instagramLink !== premiumMetaData.instagramLink || newsletterLink !== premiumMetaData.newsletterLink

  return (
    <Layout.VerticalFlex style={{ paddingBottom: 20, gap: 10 }}>
      <h4 style={{ marginBottom: 0 }}>Dit premium produkt <Tooltip description='Med premium produktet kan du lave din egen side på gogoo appen. Denne information redigere du her' /></h4>
      <PremiumVenueTextInput label="Facebook link" value={facebookLink} setValue={setFacebookLink} />
      <PremiumVenueTextInput label="Instagram link" value={instagramLink} setValue={setInstagramLink} />
      <PremiumVenueTextInput label="Nyhedsmail link" value={newsletterLink} setValue={setNewsletterLink} />
      <Layout.VerticalFlex style={{ gap: 4 }}>
      <label>Coverbillede</label>
      <Input.ImageUpload onChange={setCustomImage} exitingFile={customImage} />
    </Layout.VerticalFlex>
      <h4 style={{ marginBottom: 8 }}>Beskrivelse af dit sted</h4>
      <HTMLEditor value={html} onChange={setHtml} hideKTNInput />
      <button style={{ marginTop: 8 }} onClick={handleClick} disabled={loading || !inputHasBeenEdited}>Gem</button>
    </Layout.VerticalFlex>
  );
}

export const DetailedVenue: React.FC = () => {
  const { venueId } = useParams<{ venueId: string }>()
  const [venue, setVenue] = React.useState<KultunautVenue>()
  const [grantedAccess, setGrantedAccess] = React.useState<AdminUser[]>()
  const [requestedAccess, setRequestedAccess] = React.useState<AdminUser[]>()
  const [events, setEvents] = React.useState<KultunautEvent[]>()
  const [, setEventsForLastChance] = React.useState<KultunautEvent[]>()
  const [lastChanceEvents, setLastChanceEvents] = React.useState<KultunautEventWithLastChance[]>()

  const [loading, setLoading] = React.useState<LoadingState>()

  const init = React.useCallback(async (disableLoading?: boolean) => {
    if (!disableLoading) setLoading({ loading: Loading.LOADING })
    const venueResult = await apiHooksV2.getVenueDashboard(Number(venueId))
    if (venueResult) {
      setVenue(venueResult.venue)
      setGrantedAccess(venueResult.accessGranted)
      setRequestedAccess(venueResult.accessRequests)
      setEvents(venueResult.events)
      const filteredLastChanceEvents: KultunautEventWithLastChance[] = []
      venueResult.events.forEach((item) => {
        if (item.EventMetaData !== undefined && item.EventMetaData.ticket !== undefined) {
          const ticketItem: KultunautEventWithLastChance = {
            ...item,
            EventMetaData: {
              eventId: item.Id, ticket: item.EventMetaData.ticket,
              endDateUnix: kultuanutDateToNodeDate(item.Enddate).getTime(),
              latitude: item.Lat,
              longitude: item.Lon
            }
          }
          filteredLastChanceEvents.push(ticketItem)
        }
      })
      setLastChanceEvents(filteredLastChanceEvents)
      const filteredEventsForLastChance = venueResult.events.filter((item) => !item.EventMetaData?.ticket && item.Ticket)
      setEventsForLastChance(filteredEventsForLastChance)
      setLoading({ loading: Loading.COMPLETE })
    } else {
      if (!disableLoading) setLoading({ loading: Loading.ERROR, errorMessage: "Kunne ikke finde organisationen med id: " + venueId })
    }
  }, [venueId])



  React.useEffect(() => { init() }, [init])

  const refreshData = async () => {
    await init(true)
  }


  if (!loading || loading.loading === Loading.LOADING) return <LoadingScreen />
  if (loading.loading === Loading.ERROR) return <ErrorScreenTemplate><p>Error: {loading.errorMessage}</p></ErrorScreenTemplate>
  if (!venue) return <ErrorScreenTemplate><p>Error: Cannot find requested venue. Please contact the IT Administrator</p></ErrorScreenTemplate>

  const metrics = [
    { label: "Antal visninger", value: venue?.MetaData?.views || "Ukendt" },
    { label: "Antal følger", value: venue?.MetaData?.followers === undefined ? "Ukendt" : venue?.MetaData?.followers },
  ]
  const isPremium = venue.PremiumMetaData?.isValid || false

  return (
    <Layout.Body>
      <Navigation title={venue.Name} />
      <Layout.Container>
      <BreadCrumb links={[
          { label: "Udbyder", url: "/venues" },
          { label: venue.Name, url: "/venues/" + venue.Id },
        ]} />
        <Layout.HorizontalFlex style={{ gap: 28 }}>
        <Layout.VerticalFlex style={{ minWidth: 300, marginTop: 44, gap: 22 }}>
          <Layout.VerticalFlex >
            <b>Genveje</b>
            <a style={{}} href='https://www.gogoo.app/kontakt'>Support</a>
          </Layout.VerticalFlex>
          <Layout.VerticalFlex >
            <b>Adgange</b>
            {grantedAccess?.map((i) => <Layout.HorizontalFlex style={{ justifyContent: "space-between", borderBottom: "1px #d9d9d9 solid", paddingTop: 4, paddingBottom: 4 }}>
              <span>{i.displayName}</span>
            </Layout.HorizontalFlex>)}
          </Layout.VerticalFlex>
          <Layout.VerticalFlex >
            <b>Anmodninger</b>
            {requestedAccess?.map((i, key) => <AccessItem item={i} key={key} venueId={venue.Id} />)}
            {requestedAccess && requestedAccess.length === 0 && <span>Ingen anmodninger</span>}
          </Layout.VerticalFlex>
        </Layout.VerticalFlex>
        <Layout.VerticalFlex style={{ width: '100%' }}>
          <Layout.HorizontalFlex style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <h1>{venue.Name}</h1>
            <StatusComponent active={true} />
          </Layout.HorizontalFlex>
          <Layout.VerticalFlex style={{ flex: 1 }}>
            <Layout.HorizontalFlex style={{ gap: 14 }}>
              {metrics.map((item, key) => <DataBox key={key} label={item.label} data={item.value} />)}
            </Layout.HorizontalFlex>
            <Layout.HorizontalFlex style={{ justifyContent: 'space-between', width: '100%' }}>
              <Layout.VerticalFlex>
                <p><b>{venue.Address}, {venue.Zip}, {venue.City}</b><Tooltip description='Ønsker du at redigere denne del af informationen skal du gøre dette via Kultunaut.dk' /></p>
                <p>Beskrivelse <Tooltip description='Denne information kommer fra Kultunaut.dk og kan kun redigeres via Kultunaut.dk' />: <span>{venue.Description}</span></p>
                <p>Telefonnummer<Tooltip description='Ønsker du at redigere denne del af informationen skal du gøre dette via Kultunaut.dk' />: <b>{venue.Phone}</b></p>
              </Layout.VerticalFlex>
              <Layout.VerticalFlex>
                <a href={'https://www.kultunaut.dk/perl/retsted/type-nynaut?StedNr=' + venueId + '/#editaddress'}>Rediger på Kultunaut.dk</a>
              </Layout.VerticalFlex>
            </Layout.HorizontalFlex>
          </Layout.VerticalFlex>
        </Layout.VerticalFlex>
        </Layout.HorizontalFlex>
      </Layout.Container>
      <Layout.Container>
      <Layout.VerticalFlex style={{ backgroundColor: colors.main }}>
              <Layout.VerticalFlex style={{ padding: 14 }}>
                {!isPremium && <h4 style={{ marginBottom: 0 }}>Produkt</h4>}
                {isPremium && venue.PremiumMetaData && <PremiumVenueEditor reloadData={init} premiumMetaData={venue.PremiumMetaData} />}
                {!isPremium && <p>Der er ikke købt premium produkt</p>}
                {!isPremium && <BuyPremiumButton venueId={venue.Id} reload={init} />}
              </Layout.VerticalFlex>
            </Layout.VerticalFlex>
        <Layout.VerticalFlex>
          <Layout.HorizontalFlexJustifyCenter style={{ marginBottom: 32 }}>
            <CustomTable data={events ? eventsToTable(events) : [[]]} exportFileName={"events-" + venueId} tableHeading={<Layout.HorizontalFlex><h2 style={{ padding: 0, margin: 0 }}>Events</h2><Tooltip description='Ønsker du at redigere dine events skal du gøre dette via Kultunaut.dk' /></Layout.HorizontalFlex>}
              metrics={[{ label: "Antal events", filterFunction: (table) => table?.length || 0 }]} />
          </Layout.HorizontalFlexJustifyCenter>
          <Layout.HorizontalFlexJustifyCenter style={{ marginBottom: 32 }}>
            <CustomTable data={lastChanceEvents ? productsToTable(lastChanceEvents, refreshData) : [[]]} exportFileName={"tickets-" + venueId} tableHeading={<Layout.HorizontalFlex><h2 style={{ padding: 0, margin: 0 }}>Dine billetter</h2></Layout.HorizontalFlex>}
              metrics={[{ label: "Antal billetter", filterFunction: (table) => table?.length || 0 }]} />
          </Layout.HorizontalFlexJustifyCenter>
        </Layout.VerticalFlex>
      </Layout.Container>
    </Layout.Body>
  )
}