import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingScreen } from '../../components/atoms/Defaults';
import RequestList, { AccessVariant, RequestItem } from '../../components/molecules/RequestList';
import { Navigation } from '../../components/organisms/Navigation';
import Layout from '../../components/templates/Layout';
import hooks from '../../globals/hooks';
import { Loading, LoadingState } from '../../globals/states';
import apiHooksV2 from '../../globals/api-hooks-v2';
import { Organisation } from 'gogoo-common/content/Organisation';
import { IsGogooAdmin, OrganisationAuthorizationTypes } from '../../globals/global-autorisations';
import { ErrorScreenTemplate } from '../../components/templates/ErrorScreen';

const orgSort = (orgList: Organisation[]): Organisation[] => orgList.sort((orgA, orgB) => orgA.name.toLowerCase().localeCompare(orgB.name.toLowerCase()))

const orgToRequestItem = (org: Organisation): RequestItem => {
  return {
    id: org.organizationId,
    name: org.name,
    status: "Aktiv"
  };
}

export const OrganizationIndex: React.FC = () => {
  const navigate = useNavigate()
  const [organizationsAccess, setOrganizationsAccess] = React.useState<Organisation[]>([])
  const [unauthorizedOrganizations, setUnauthorizedOrganizations] = React.useState<Organisation[]>([])

  const [loading, setLoading] = React.useState<LoadingState>()
  const [requestSend, setRequestSend] = React.useState<{name: string, id: string}[]>([])

  const init = async () => {
    setLoading({ loading: Loading.LOADING })
    const user = await apiHooksV2.getAdminUser()
    const dashboard = await apiHooksV2.listUserOrganisations()
    if (dashboard) {
      setLoading({ loading: Loading.COMPLETE })
      if (IsGogooAdmin(user)) {
        setOrganizationsAccess(orgSort(dashboard))
      }
      else {
        const access = user.organisationAuthorizations.filter((item) => item.authorization > OrganisationAuthorizationTypes.ORGANIZATION_ACCESS_REQUESTED_MEMBER).map((i) => i.organizationId)
        setOrganizationsAccess(orgSort(dashboard.filter((i) => access.includes(i.organizationId))))
        setUnauthorizedOrganizations(orgSort(dashboard.filter((i) => !access.includes(i.organizationId))))
      }
    } else {
      setLoading({ loading: Loading.ERROR, errorMessage: "Could not load organizations" })
    }
  }

  const resetUser = async () => {
    setLoading({ loading: Loading.LOADING })
    await hooks.resetUser()
    await init()
  }

  const requestOrganisationAccess = async (organisationName: string, organisationId: string) => {
    const user = await apiHooksV2.getAdminUser()
    await apiHooksV2.sendSupportMessage(`[ADMIN]: Anmodning om adgang til ${organisationName} (${organisationId})`, user.email)
    setRequestSend([...requestSend, {name: organisationName, id: organisationId}])
    setUnauthorizedOrganizations(unauthorizedOrganizations.filter((i) => i.organizationId !== organisationId))
  }

  React.useEffect(() => { init() }, [])

  if (!loading || loading.loading === Loading.LOADING) return <LoadingScreen />
  if (loading.loading === Loading.ERROR) return <ErrorScreenTemplate><p>Error: {loading.errorMessage}</p></ErrorScreenTemplate>

  return (
    <Layout.Body>
      <Navigation />
      <Layout.Container style={{ gap: 12 }}>
        <Layout.VerticalFlex>
          <h2>Dine organisationer</h2>
          <RequestList availableItems={organizationsAccess.map(orgToRequestItem)} variant={AccessVariant.ACCESS_GRANTED} onClick={async (item) => navigate("/organisations/" + item.id)} />
        </Layout.VerticalFlex>
        <Layout.VerticalFlex>
          <h2>Dine anmodninger</h2>
          <RequestList availableItems={requestSend.map((i)=> ({...i, status: "Afventer"}))} variant={AccessVariant.REQUEST_SEND}  />
        </Layout.VerticalFlex>
        <Layout.VerticalFlex>
          <h2>Andre organisationer</h2>
          <RequestList availableItems={unauthorizedOrganizations.map(orgToRequestItem)} variant={AccessVariant.OTHER} onClick={async (item) => await requestOrganisationAccess(item.name, item.id)} />
        </Layout.VerticalFlex>
        <Layout.HorizontalFlex style={{ justifyContent: 'end' }}>
          <button onClick={resetUser} style={{ marginTop: 20 }}>
            <span>Nulstil bruger</span>
          </button>
        </Layout.HorizontalFlex>
      </Layout.Container>
    </Layout.Body>

  );
}