import * as React from 'react';
import Layout from '../templates/Layout';
import { colors } from '../../globals/colors';
import HTMLEditor from '../atoms/HtmlEditor';
import { BlogPostPreview } from '../atoms/AppComponents';
import Input from '../atoms/Input';
import { GoogleSearchResult } from '../atoms/SEOComponents';
import { CreateBlogPost } from 'gogoo-common/admin/BlogPost';
import { BlogPost, BlogPostTags, BlogTemplateItems } from 'gogoo-common/content/BlogPost';
import { AddElementButton, TemplateItemControls } from '../molecules/UIEditor';
import { DetailedEventTemplate, HTMLElmentTemplate } from 'gogoo-common/feed/feed-items';
import apiHooksV2 from '../../globals/api-hooks-v2';
import { kultuanutDateToNodeDate } from '../../globals/helpers';


enum InputVariants {
    Default = "Default",
    RichText = "RichText",
    TextArea = "TextArea",
    ImageUpload = "ImageUpload",
    TagSelect = "TagSelect"
}
const EditBlogPostItem: React.FC<{
    name: string, value: string, setter(val: string): void,
    variant?: InputVariants, error?: boolean, overrideMarginBottom?: number
}> = ({ name, value, setter, variant, error, overrideMarginBottom }) => {
    const hideCount = variant === InputVariants.RichText || variant === InputVariants.ImageUpload || variant === InputVariants.TagSelect
    const getInputVariant = () => {
        if (variant === InputVariants.TextArea) return <textarea style={{ height: 96 }} value={value} onChange={(e) => setter(e.target.value)} />
        if (variant === InputVariants.RichText) return <HTMLEditor value={value} onChange={setter} />
        if (variant === InputVariants.ImageUpload) return <Input.ImageUpload onChange={setter} />
        if (variant === InputVariants.TagSelect) return <Input.Select selected={value ? { displayName: value, key: value } : undefined}
            items={[BlogPostTags.Experience, BlogPostTags.FreeGuides,
            BlogPostTags.Guides, BlogPostTags.Personalities].map((i) => ({ displayName: i, key: i }))} onSelect={(e) => setter(e.displayName)} />
        return <input value={value} onChange={(e) => setter(e.target.value)} />
    }

    return (
        <Layout.VerticalFlex style={{
            marginBottom: overrideMarginBottom === undefined ? 12 : overrideMarginBottom,
            borderStyle: "solid", borderWidth: 2, borderColor: error ? colors.danger : "transparent"
        }}>
            <Layout.HorizontalFlex style={{ justifyContent: 'space-between' }}>
                <b style={{ marginBottom: 3 }}>{name}</b>
                {!hideCount && <span style={{ marginBottom: 3, fontSize: 11 }}>{value.length} tegn</span>}
            </Layout.HorizontalFlex>
            {getInputVariant()}
        </Layout.VerticalFlex>
    )
}

const BlogEventItem: React.FC<{ item: DetailedEventTemplate, onChange(_: DetailedEventTemplate): void }> = ({ item, onChange }) => {
    const [eventId, setEventId] = React.useState(item.eventId)
    const [loading, setLoading] = React.useState(false)
    const [eventTitle, setEventTitle] = React.useState(item.eventTitle)
    const [endDateTime, setEndDateTime] = React.useState(item.endDateTime)
    const [image, setImage] = React.useState<string>(item.eventImage)

    const disableSave = eventId === item.eventId && eventTitle === item.eventTitle && endDateTime === item.endDateTime

    const loadEvent = async () => {
        if (isNaN(eventId) || eventId < 0 || disableSave) return
        setLoading(true)
        const eventDataResponse = await apiHooksV2.getEventDashboard(eventId)
        if (eventDataResponse) {
            const localEventTitle = eventDataResponse.event.Title
            setEventTitle(localEventTitle)
            const localEndDateTimeIso = kultuanutDateToNodeDate(eventDataResponse.event.Enddate).toISOString()
            setEndDateTime(localEndDateTimeIso)

            const modifiedImage = eventDataResponse.event.Image.split('W480H225').join('W480')
            setImage(modifiedImage)
            onChange({
                "eventId": eventId,
                "type": "DetailedEventTemplate",
                "endDateTime": localEndDateTimeIso,
                "eventTitle": localEventTitle,
                "eventImage": modifiedImage
            })
        }
        setLoading(false)
    }

    React.useEffect(() => {
        loadEvent()
    }, [])


    return (
        <Layout.HorizontalFlex style={{ gap: 12, color: colors.primary }}>
            <Layout.VerticalFlex style={{ flex: 3, gap: 12 }}>
                <Layout.VerticalFlex style={{ gap: 6 }}>
                    <span>Event id:</span>
                    <Layout.HorizontalFlexAlignCenter style={{ gap: 12 }}>
                        <input disabled={loading} type='number' onChange={(e) => setEventId(Number(e.target.value))} value={eventId > 0 && eventId || undefined} />
                        {loading ? <span>Henter data...</span> : <button disabled={disableSave} onClick={loadEvent}>Hent event data</button>}
                    </Layout.HorizontalFlexAlignCenter>
                </Layout.VerticalFlex>
            </Layout.VerticalFlex>
            <Layout.VerticalFlex style={{ flex: 2 }}>
                <div style={{
                    flex: 1, minHeight: 160,
                    backgroundImage: !image ? '' : `url(${image})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    borderRadius: 16
                }} />
                <Layout.VerticalFlex style={{ minHeight: 60, background: colors.blog, borderRadius: 16, color: colors.white, padding: 12, textAlign: 'center' }}>
                    <h3 >{eventTitle}</h3>
                    {endDateTime && <span>Slut dato: {endDateTime?.split('T').join(' - ').split(':00.000Z').join('')}</span>}
                    <i style={{ fontSize: 12, opacity: 0.5 }}>Datoen vil automatisk optræde på appen</i>
                </Layout.VerticalFlex>
            </Layout.VerticalFlex>
        </Layout.HorizontalFlex>
    )
}

const ControlsRow: React.FC<{ disableSave: boolean, cancel?: () => void, submit(): void }> = ({ disableSave, cancel, submit }) => {

    return (
        <Layout.HorizontalFlex style={{ gap: 12, justifyContent: 'space-between', }}>
            {cancel && <button disabled={disableSave} style={{
                background: colors.white, color: colors.primary,
                opacity: disableSave ? 0.4 : 1,
                border: `${colors.primary} 1px solid`
            }}
                onClick={cancel}>Annuller ændringer</button>}
            <button disabled={disableSave} onClick={submit}>Gem ændringer</button>
        </Layout.HorizontalFlex>
    )
}

const HTMLElementItem: React.FC<{ item: HTMLElmentTemplate, onChange(_: HTMLElmentTemplate): void }> = ({ item, onChange }) => {
    const [data, setData] = React.useState(item.data)

    const handleChange = () => {
        onChange({ "type": "HTMLElmentTemplate", data })
    }

    const disableSave = data === item.data

    return (
        <Layout.HorizontalFlex style={{ gap: 12 }}>
            <Layout.VerticalFlex style={{ flex: 3, gap: 6 }}>
                <HTMLEditor value={data} onChange={setData} hideKTNInput />
                <ControlsRow disableSave={disableSave} submit={handleChange} />
            </Layout.VerticalFlex>
            <Layout.VerticalFlex style={{ flex: 2, color: colors.blog }}>
                <div dangerouslySetInnerHTML={{ "__html": data }} className='blogContent'></div>
            </Layout.VerticalFlex>
        </Layout.HorizontalFlex>
    )
}

const dateInputHandler = (input: string) => {
    const date = new Date(input)
    return date.toISOString()
}

const dateOutputHandler = (input: string) => {
    const date = new Date(input)
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
    date.setSeconds(0, 0)
    return date.toISOString().replace('Z', '')
}

const getToday = () => {
    const now = new Date()

    return (new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes())).toISOString()
}


const BlogTemplateItem: React.FC<{
    template: BlogTemplateItems[], index: number,
    setTemplate(_: BlogTemplateItems[]): void, item: BlogTemplateItems
}> = ({ template, index, setTemplate, item }) => {

    const [deleteConfirmation, setDeleteConfirmation] = React.useState(false)

    const move = (val: number) => {
        const newList = template
        const position = template[index + val]
        const current = template[index]
        newList[index + val] = current
        newList[index] = position
        setTemplate(newList)
    }

    const deleteItem = () => {
        const newList = template
        newList.splice(index, 1);
        setTemplate(newList)
        setDeleteConfirmation(false)
    }

    const onChange = (updated: BlogTemplateItems) => {
        const newList = template
        newList[index] = updated
        setTemplate(newList)
    }

    const Wrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => (
        <Layout.VerticalFlex style={{ background: colors.white, padding: 12, gap: 12 }}>
            <TemplateItemControls deleteConfirmation={deleteConfirmation} setDeleteConfirmation={setDeleteConfirmation} move={move}
                index={index} length={template.length} deleteItem={deleteItem} />
            {children}
        </Layout.VerticalFlex>
    )


    if (item.type === 'DetailedEventTemplate') return <Wrapper><BlogEventItem item={item} onChange={onChange} /></Wrapper>
    if (item.type === "HTMLElmentTemplate") return <Wrapper><HTMLElementItem item={item} onChange={onChange} /></Wrapper>

    return (
        <Wrapper>
            <Layout.HorizontalFlexAlignCenter style={{ flex: 1, minHeight: 200 }}> Ukendt type: {JSON.stringify(item)}</Layout.HorizontalFlexAlignCenter>
        </Wrapper>
    )
}

const BlogPostTemplateEditor: React.FC<{ template: BlogTemplateItems[], setTemplate(_: BlogTemplateItems[]): void }> = ({ template, setTemplate }) => {

    const addEmptyEvent = () => {
        setTemplate([...template,
        {
            type: "DetailedEventTemplate",
            "endDateTime": "",
            "eventId": -1, //-1,
            "eventTitle": "",
            eventImage: ""
        }])
    }


    const updateTemplate = (newTemplate: BlogTemplateItems[]) => {
        setTemplate([...newTemplate])
    }

    return (
        <Layout.VerticalFlex style={{ gap: 12 }}>
            <Layout.HorizontalFlex style={{ gap: 32 }}>
                <AddElementButton label='Tilføj tekst element' onClick={() => setTemplate([...template, { type: "HTMLElmentTemplate", "data": "" }])} />
                <AddElementButton label='Tilføj event' onClick={addEmptyEvent} />
            </Layout.HorizontalFlex>
            {template.map((item, key) => <BlogTemplateItem item={item} key={item.id} setTemplate={updateTemplate} template={template} index={key} />)}
        </Layout.VerticalFlex>
    )
}



type BlogPostEditorProps = {
    initial?: BlogPost, organizationId?: string, onCreate?: (props: CreateBlogPost) => void,
    onUpdate?: (props: BlogPost) => void, onDelete?: (id: string) => void
}
export const BlogPostEditor: React.FC<BlogPostEditorProps> = ({ initial, organizationId, onCreate, onUpdate, onDelete }) => {

    const [name, setName] = React.useState(initial?.name)
    const [shorttitle, setShortTitle] = React.useState(initial?.shorttitle)
    const [summary, setSummary] = React.useState(initial?.summary)
    const [content, setContent] = React.useState(initial?.content)
    const [thumbnail, setThumbnail] = React.useState(initial?.thumbnail)
    const [tag, setTag] = React.useState<BlogPostTags | undefined>(initial?.blogPostTag)
    const [useTemplateEditor, setUseTemplateEditor] = React.useState(!initial?.content?.length)
    const [template, setTemplate] = React.useState<BlogTemplateItems[]>(initial?.template || [])
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>()
    const [publishDate, setPublishDate] = React.useState(initial?.publishDate ? new Date(initial?.publishDate).toISOString() : getToday())

    const handleNext = () => {
        if (name && shorttitle && summary && thumbnail) {
            const data: CreateBlogPost = {
                organizationOwner: organizationId,
                name,
                shorttitle,
                summary,
                content: content?.split('<p><br></p>').join('') || '',
                thumbnail,
                template,
                blogPostTag: tag,
                publishDate: new Date(publishDate).getTime()
            }
            if (initial && onUpdate) onUpdate({ ...initial, ...data })
            else if (onCreate) onCreate(data)
        } else {
            if (!name) setErrorMessage('Kort titel mangler')
            if (!shorttitle) setErrorMessage('Lang titel mangler')
            if (!summary) setErrorMessage('Resumé mangler')
            if (!thumbnail) setErrorMessage('Miniature billede mangler')
        }
    }

    const handleTagChange = (e: string) => {
        const localTag = e as BlogPostTags
        setTag(localTag)
    }

    return (
        <Layout.VerticalFlex>
            <Layout.HorizontalFlex>
                <Layout.VerticalFlex style={{ flex: 1, flexGrow: 3, paddingRight: 20, maxWidth: 300 }}>
                    <EditBlogPostItem setter={setName} value={name || ''} name={"Kort title (Max 30 tegn)"} />
                    <EditBlogPostItem setter={setShortTitle} value={shorttitle || ''} name={"Lang title (Max 50 tegn)"} />
                    <EditBlogPostItem setter={setSummary} value={summary || ''} name={"Resumé (70-155 tegn)"} variant={InputVariants.TextArea} />
                    <EditBlogPostItem setter={setThumbnail} value={thumbnail || ''} name={"Miniature billede"} variant={InputVariants.ImageUpload} />
                    <EditBlogPostItem setter={handleTagChange} value={tag || ''} name={"Tag"} variant={InputVariants.TagSelect} />
                    <span style={{ marginTop: 0, marginBottom: 6 }}>Google preview</span>
                    <GoogleSearchResult title={shorttitle || ''} description={summary || ''} />
                    <span style={{ marginTop: 12, marginBottom: 6 }}>App preview</span>
                    <BlogPostPreview image={thumbnail} title={shorttitle} description={summary} blogPostTag={tag} />
                </Layout.VerticalFlex>
                <Layout.VerticalFlex style={{ flex: 3, gap: 12 }}>
                    <Layout.HorizontalFlexAlignCenter style={{ gap: 12 }}>
                        <span>Brug dynamisk indhold</span>
                        <Input.TogleSwitch onCLick={() => setUseTemplateEditor(!useTemplateEditor)} value={useTemplateEditor} />
                    </Layout.HorizontalFlexAlignCenter>
                    <Layout.VerticalFlex style={{ display: useTemplateEditor ? 'none' : 'flex' }}>
                        <h2>Statisk indhold</h2>
                        <EditBlogPostItem setter={setContent} value={content || ''} name="" variant={InputVariants.RichText} overrideMarginBottom={0} />
                    </Layout.VerticalFlex>
                    <Layout.VerticalFlex style={{ display: !useTemplateEditor ? 'none' : 'flex' }}>
                        <h2>Dynamisk indhold</h2>
                        <BlogPostTemplateEditor template={template} setTemplate={setTemplate} />
                    </Layout.VerticalFlex>
                </Layout.VerticalFlex>
            </Layout.HorizontalFlex>
            <Layout.HorizontalFlex style={{ justifyContent: 'flex-end', marginTop: 12, gap: 12 }}>
                {onDelete && initial && <button onClick={() => onDelete(initial.id)} style={{ background: colors.danger, color: colors.white }}>Slet opslag</button>}
                <Input.InputWithSuffix onChange={(v) => setPublishDate(dateInputHandler(v))} customWidth={200} suffix='udgivelse' type='datetime-local' value={dateOutputHandler(publishDate)} />
                {errorMessage && errorMessage.length && <span style={{ color: colors.danger }}>{errorMessage}</span>}
                <button onClick={handleNext}>Gem og udgiv</button>
            </Layout.HorizontalFlex>
        </Layout.VerticalFlex>
    );
}