
const stringToStringArray = (term: string): string[] => {
    const arr: string[] = []
    for (let index = 0; index < term.length; index++) {
        const element = term[index];
        arr.push(element)
    }
    return arr
}

export const NiceNumber = (input: number): string => {
    const number = input.toFixed(0)
    const numberAsString = (number.toString())
    const numberAsArray = stringToStringArray(numberAsString).reverse()
    let formattedOutput = ""
    for (let index = 0; index < numberAsArray.length; index++) {
        const element = numberAsArray[index];
        if (index > 0 && index % 3 === 0) formattedOutput = formattedOutput + '.'
        formattedOutput = formattedOutput + element
    }

    const formattedOutputArray = stringToStringArray(formattedOutput).reverse()

    return formattedOutputArray.join('')
}

export type ReactChildren = { children?: React.ReactNode | React.ReactNode[] }

function formatTime(timeStr: string) {
    // Remove "kl." and any spaces
    timeStr = timeStr.replace("kl.", "").trim();

    // Split on any hyphen or dash to get the start time
    let startTime = timeStr.split('-')[0].trim();

    // Replace any "." with ":"
    startTime = startTime.replace(".", ":");

    // If there's no ":", it's a whole hour, so append ":00"
    if (!startTime.includes(":")) {
        startTime += ":00";
    }

    // If there's only one digit for the hour, prepend a "0"
    if (startTime.length === 4) {
        startTime = "0" + startTime;
    }

    return startTime;
}


export const kultuanutDateToNodeDate = (date: string, time?: string): Date => {
    if (!date) return new Date()
    const dateArray = date.split('-')
    if (isNaN(Number(dateArray[0])) || isNaN(Number(dateArray[1])) || isNaN(Number(dateArray[2]))) {
        return new Date()
    }
    const nodeDate = new Date()
    nodeDate.setDate(Number(dateArray[0]))
    nodeDate.setMonth(Number(dateArray[1]) - 1)
    nodeDate.setFullYear(Number(dateArray[2]))
    nodeDate.setHours(12, 0, 0, 0)

    if (time) {
        const formattedTime = formatTime(time)
        if (formattedTime.includes(':')) {
            const timeArray = formattedTime.split(':')
            nodeDate.setMinutes(Number(timeArray[1]))
            nodeDate.setHours(Number(timeArray[0]))
        } else {
            nodeDate.setMinutes(0)
            nodeDate.setHours(12)
        }
    }
    return nodeDate
}


export const nodeDateToKTNDate = (date: Date): string => {
    let day = (date.getDate()).toString()
    let month = (date.getMonth() + 1).toString()

    if (day.length === 1) day = '0' + day
    if (month.length === 1) month = '0' + month

    return day + '-' + month + '-' + date.getFullYear()
}

export const NiceDate = (date: Date): string => {

    const monthMap = ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "aug", "sep", "okt", "nov", "dec"]

    return date.getDate() + '. ' + monthMap[date.getMonth()]
}


export const NiceDateTime = (date: Date): string => {

    const monthMap = ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "aug", "sep", "okt", "nov", "dec"]

    const timeFormatter = (time: number) => {
        if(time < 10) return '0' + time
        return time.toString()
    }

    return date.getDate() + '. ' + monthMap[date.getMonth()] + " - "+ timeFormatter(date.getHours()) + ":" + timeFormatter(date.getMinutes())
}