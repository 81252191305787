import Layout from './Layout';
import { useNavigate } from 'react-router-dom';



export const ErrorScreenTemplate: React.FC<{ children: React.ReactNode }> = ({ children }) => {

    const navigation = useNavigate()

    return (
        <Layout.Body>
            <Layout.Container style={{marginTop: 120, marginBottom: 120, backgroundColor: 'red', color: 'white', padding: "34px 12px", borderRadius: 4}}>
                {children}
            </Layout.Container>
            <Layout.Container>
                <Layout.HorizontalFlex style={{gap: 12}}>
                <button onClick={()=> navigation('/')}>Forsiden</button>
                <button onClick={()=> navigation('/organisations')}>Organisationer</button>
                <button onClick={()=> navigation('/venues')}>Event steder</button>

                </Layout.HorizontalFlex>
            </Layout.Container>
        </Layout.Body>
    )
}