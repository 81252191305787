import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { LogoutPage, LoginPage } from './Auth';
import Configs from '../globals/config'
import { IndexPage } from './Index';
import { DetailedOrganization } from './Organization/detailed';
import { Amplify, Auth } from 'aws-amplify'
import { EditOrganizationPage } from './Organization/edit';
import { FeedPage } from './Organization/feed';
import { OrganizationIndex } from './Organization';
import { VenuesIndex } from './Venues';
import { DetailedVenue } from './Venues/detailed';
import { DetailedEvent } from './Events/detailed';
import { OrganisationBlogPage } from './Organization/blog';
import { BannerPage } from './Organization/banner';
import { BlogListPage } from './Blog';
import { LoadingScreen } from '../components/atoms/Defaults';
import { BlogPage } from './Blog/detailed';

export default function RootRouter() {
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true)
  Amplify.configure(Configs)

  const checkSignedInUser = async () => {
    setLoading(true)

    try {
      const receivedUser = await Auth.currentAuthenticatedUser()
      if (receivedUser) setUser(receivedUser)
      else setUser(null)
    } catch (error) {
      setUser(null)
    }
    setLoading(false)
  }

  React.useEffect(() => {
    checkSignedInUser()
  }, []);


  if (!loading) return (
    <BrowserRouter>
      {user && !loading && (
        <Routes>
          <Route path="/" element={<IndexPage />} />
          <Route path="/organisations" element={<OrganizationIndex />} />
          <Route path="/venues" element={<VenuesIndex />} />
          <Route path="/venues/:venueId" element={<DetailedVenue />} />
          <Route path="/organisations/:organizationId/feeds/create" element={<FeedPage create={true} />} />
          <Route path="/organisations/:organizationId/feeds/:feedId" element={<FeedPage />} />
          <Route path="/organisations/:organizationId/blogs/create" element={<OrganisationBlogPage create={true} />} />
          <Route path="/organisations/:organizationId/blogs/:blogId" element={<OrganisationBlogPage />} />
          <Route path="/organisations/:organizationId" element={<DetailedOrganization />} />
          <Route path="/organisations/:organisationId/edit" element={<EditOrganizationPage />} />
          <Route path="/organisations/:organizationId/banners/create" element={<BannerPage create={true} />} />
          <Route path="/organisations/:organizationId/banners/:bannerId" element={<BannerPage />} />
          <Route path="/events/:eventId" element={<DetailedEvent />} />
          <Route path="/blogs" element={<BlogListPage />} />
          <Route path="/blogs/create" element={<BlogPage create={true} />} />

          <Route path="/blogs/:blogId" element={<BlogPage />} />
          <Route path="/logout" element={<LogoutPage />} />
        </Routes>
      )}
      {!user && !loading &&
        <Routes>
          <Route path="/*" element={<LoginPage />} />
        </Routes>
      }
    </BrowserRouter>
  )
  return <LoadingScreen />

}
