import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingScreen } from '../../components/atoms/Defaults';
import { Navigation } from '../../components/organisms/Navigation';
import Layout from '../../components/templates/Layout';
import { Loading, LoadingState } from '../../globals/states';
import apiHooksV2 from '../../globals/api-hooks-v2';
import { BreadCrumb } from '../../components/molecules/BreadCrumb';
import { BlogPost, BlogPostPreview } from 'gogoo-common/content/BlogPost';
import CustomTable from '../../components/molecules/CustomTable';
import { NiceDate, NiceDateTime } from '../../globals/helpers';
import { ErrorScreenTemplate } from '../../components/templates/ErrorScreen';


export const BlogListPage: React.FC<{}> = ({ }) => {
    const navigate = useNavigate()
    const [loading, setLoading] = React.useState<LoadingState>()
    const [blogPost, setBlogPost] = React.useState<BlogPostPreview[]>()

    const init = React.useCallback(async () => {
        setLoading({ loading: Loading.LOADING })
        const result = await apiHooksV2.getBlogPost()
        if (result) {
            setBlogPost(result)
            setLoading({ loading: Loading.COMPLETE })


        } else {
            setLoading({ loading: Loading.ERROR, errorMessage: "Kunne ikke finde blog indhold" })
        }
    }, [])

    React.useEffect(() => { init() }, [init])

    if (!loading || loading.loading === Loading.LOADING) return <LoadingScreen />
    if (loading.loading === Loading.ERROR) return <ErrorScreenTemplate><p>Error: {loading.errorMessage}</p></ErrorScreenTemplate>
    if (!blogPost) return <ErrorScreenTemplate>Error loading blog posts</ErrorScreenTemplate>

    return (
        <Layout.Body>
            <Navigation title="Blog admin" />
            <Layout.Container>
                <Layout.HorizontalFlex style={{ justifyContent: 'space-between', marginLeft: 14, marginRight: 14, alignItems: 'center' }}>
                    <BreadCrumb links={[
                        { label: "Admin", url: "/blogs" },
                        { label: "Blogs", url: "/blogs" }
                    ]} />
                    <button onClick={()=> navigate('/blogs/create')}>Opret ny</button>
                </Layout.HorizontalFlex>
            </Layout.Container>
            <Layout.Container>
                <CustomTable data={[
                    ["Title", "Oprettet", "Udgivet", "Type", "Link"], 
                ...blogPost.map((i) => ([
                    i.name,
                    NiceDate(new Date(i.createdAt)),
                    NiceDateTime(new Date(i.publishDate)),
                    i.template ? "Dynamic" : "Regular",
                    <button style={{cursor: 'pointer'}} onClick={()=> navigate('/blogs/'+i.id)}>Rediger</button>
                ]))]} />
            </Layout.Container>
        </Layout.Body>
    )
}