import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingScreen } from '../../components/atoms/Defaults';
import { Navigation } from '../../components/organisms/Navigation';
import Layout from '../../components/templates/Layout';
import { colors } from '../../globals/colors';
import hooks from '../../globals/hooks';
import { Loading, LoadingState } from '../../globals/states';
import styled from 'styled-components';
import { AdminUser } from 'gogoo-common/admin/AdminUser';
import Input, { KeyDisplayNamePair } from '../../components/atoms/Input';
import apiHooksV2 from '../../globals/api-hooks-v2';
import { Organisation } from 'gogoo-common/content/Organisation';
import { BlogPostPreview } from 'gogoo-common/content/BlogPost';
import { StatusComponent } from '../../components/atoms/StatusComponent';
import { OrganisationAuthorizationTypes } from '../../globals/global-autorisations';
import CustomTable from '../../components/molecules/CustomTable';
import { BreadCrumb } from '../../components/molecules/BreadCrumb';
import { Banner } from 'gogoo-common/content/Banner';
import { KultunautVenue } from 'gogoo-common/content/Kultunaut';
import { CreateVenueOrganisation } from '../../components/molecules/CreateVenueOrganisation';
import { ErrorScreenTemplate } from '../../components/templates/ErrorScreen';

export const OrganisationAuthorizationTypesToString = (authType: OrganisationAuthorizationTypes): string => {
  switch (authType) {
    case OrganisationAuthorizationTypes.ORGANIZATION_ADMIN: return "Admin"
    case OrganisationAuthorizationTypes.ORGANIZATION_READ_WRITE_MEMBER: return "Fuld adgang"
    case OrganisationAuthorizationTypes.ORGANIZATION_READ_MEMBER: return "Læse adgang"
    case OrganisationAuthorizationTypes.ORGANIZATION_ACCESS_REQUESTED_MEMBER: return "Anmodning om adgang"
    default: return "Ingen adgang"
  }
}

const StateText = styled.span`
    color: ${colors.white};
    text-align: center;
`;


const getOrgAuthorization = (organizationId: string, user: AdminUser) => {
  const item = user.organisationAuthorizations.find((i) => i.organizationId === organizationId)?.authorization
  return item || 0
}



const OrganizationMemberRow: React.FC<{ index: number, user: AdminUser, orgId: string }> = ({ index, user, orgId }) => {

  return (
    <Layout.HorizontalFlex style={{
      borderTopWidth: 1, borderTopColor: colors.white, borderTopStyle: index === 0 ? 'none' : 'solid',
      paddingTop: 5, paddingBottom: 5, justifyContent: 'space-between'
    }}>
      <span style={{ fontSize: 12 }}>{user.displayName}</span>
      <span style={{ fontSize: 12, textAlign: "right" }}>{OrganisationAuthorizationTypesToString(getOrgAuthorization(orgId, user))}</span>
    </Layout.HorizontalFlex>
  )
}

enum State { None, Loading, Sucess, Error }

const OrganizationMemberRequestRow: React.FC<{ index: number, user: AdminUser, submit(userId: string, permission: OrganisationAuthorizationTypes): Promise<boolean> }> = ({ index, user, submit }) => {
  const [authOption, setAuthOption] = React.useState<KeyDisplayNamePair>()
  const [state, setState] = React.useState<State>(State.None)

  const options: KeyDisplayNamePair[] = [
    { key: OrganisationAuthorizationTypes.ORGANIZATION_READ_MEMBER, displayName: OrganisationAuthorizationTypesToString(OrganisationAuthorizationTypes.ORGANIZATION_READ_MEMBER) },
    { key: OrganisationAuthorizationTypes.ORGANIZATION_READ_WRITE_MEMBER, displayName: OrganisationAuthorizationTypesToString(OrganisationAuthorizationTypes.ORGANIZATION_READ_WRITE_MEMBER) },
    { key: OrganisationAuthorizationTypes.ORGANIZATION_ADMIN, displayName: OrganisationAuthorizationTypesToString(OrganisationAuthorizationTypes.ORGANIZATION_ADMIN) },
    { key: OrganisationAuthorizationTypes.NONE, displayName: OrganisationAuthorizationTypesToString(OrganisationAuthorizationTypes.NONE) },
  ]

  const handleSubmit = async () => {
    if (!authOption) return;
    setState(State.Loading)
    const key = Number(authOption.key)
    const result = await submit(user.userId, key)
    if (result) setState(State.Sucess)
    else if (!result) setState(State.Error)
  }
  if (state === State.Loading) return <StateText>LOADING</StateText>
  if (state === State.Sucess) return <StateText>ADGANG GODKENDT</StateText>
  if (state === State.Error) return <StateText>DER SKETE EN FEJL</StateText>

  return (
    <Layout.VerticalFlex style={{
      borderTopWidth: 1, borderTopColor: colors.white, borderTopStyle: index === 0 ? 'none' : 'solid',
      paddingTop: 10, paddingBottom: 20, justifyContent: 'space-between'
    }}>
      <span style={{ marginBottom: 10 }}>{user.displayName}</span>
      <Input.Select onSelect={setAuthOption} selected={authOption} items={options} />
      <button style={{ marginTop: 10 }} disabled={!authOption} onClick={handleSubmit}>GODKEND</button>
    </Layout.VerticalFlex>
  )
}

export const DetailedOrganization: React.FC = () => {
  const { organizationId } = useParams<{ organizationId: string }>()
  const [organization, setOrganization] = React.useState<Organisation>()
  const [accessGranted, setAccessGranted] = React.useState<AdminUser[]>([])
  const [accessRequest, setAccessRequest] = React.useState<AdminUser[]>([])

  const [blogPosts, setBlogPosts] = React.useState<BlogPostPreview[]>([])
  const [banners, setBanners] = React.useState<Banner[]>([])
  const [venues, setVenues] = React.useState<KultunautVenue[]>([])


  const [loading, setLoading] = React.useState<LoadingState>()

  const getData = async () => {
    setLoading({ loading: Loading.LOADING })
    const result = organizationId && await apiHooksV2.getOrgansationsDashboard(organizationId)
    if (result) {
      setOrganization(result.organisation)
      const access = result.users.filter((user) => getOrgAuthorization(organizationId, user) > OrganisationAuthorizationTypes.ORGANIZATION_ACCESS_REQUESTED_MEMBER)
      const requests = result.users.filter((user) => getOrgAuthorization(organizationId, user) === OrganisationAuthorizationTypes.ORGANIZATION_ACCESS_REQUESTED_MEMBER)

      setAccessGranted(access)
      setAccessRequest(requests)
      setBlogPosts(result.blogPosts)
      setBanners(result.banners)
      if (result.venues) setVenues(result.venues)
      setLoading({ loading: Loading.COMPLETE })
    } else {
      setLoading({ loading: Loading.ERROR, errorMessage: "Kunne ikke finde organisationen med id: " + organizationId })
    }
  }

  React.useEffect(() => { getData() }, [])

  const handleSubmit = async (userId: string, permission: OrganisationAuthorizationTypes) => {
    if (organizationId) return await hooks.approveAccessToOrganization({ adminUserId: userId, permission, organizationId })
    return await hooks.approveAccessToOrganization({ adminUserId: userId, permission, organizationId: "null" })
  }

  const handleDeleteConnection = async (venueId: number) => {
    if (!organization) return
    setLoading({ loading: Loading.LOADING })
    await apiHooksV2.updateOrganisation({
      ...organization,
      associatedVenues: organization.associatedVenues?.filter((i) => i.venueId !== venueId) || []
    })
    await getData()
  }

  if (!loading || loading.loading === Loading.LOADING) return <LoadingScreen />
  if (loading.loading === Loading.ERROR) return <ErrorScreenTemplate><p>Error: {loading.errorMessage}</p></ErrorScreenTemplate>
  if (!organization) return <ErrorScreenTemplate><p>Error: Contact IT Admin</p></ErrorScreenTemplate>

  return (
    <Layout.Body>
      <Navigation title={organization.name} />
      <Layout.Container>
        <BreadCrumb links={[
          { label: "Organisationer", url: "/organisations" },
          { label: organization.name, url: "/organisations/" + organization.organizationId },
        ]} />
        <Layout.HorizontalFlex style={{ justifyContent: 'space-between', marginLeft: 14, marginRight: 14, alignItems: 'center' }}>
          <h1>{organization.name}</h1>
          <StatusComponent active={true} />
        </Layout.HorizontalFlex>
        <Layout.HorizontalFlex style={{ flexGrow: 3 }}>
          <Layout.VerticalFlex style={{ flex: 1 }}>
            <Layout.Card style={{ backgroundColor: colors.main }}>
              <Layout.VerticalFlex>
                <button onClick={() => window.location.href = "/organisations/" + organizationId + "/edit"}>Rediger Organisationens UI</button>
              </Layout.VerticalFlex>
            </Layout.Card>
            {accessRequest && (
              <Layout.Card style={{ backgroundColor: colors.main }}>
                <Layout.VerticalFlex>
                  <h4 style={{ margin: 0 }}>Anmodninger</h4>
                  {accessRequest.map((item, key) => <OrganizationMemberRequestRow key={key} index={key} user={item} submit={handleSubmit} />)}
                  {accessRequest.length === 0 && <span style={{ fontSize: 12, textAlign: "center" }}>Ingen anmodninger</span>}
                </Layout.VerticalFlex>
              </Layout.Card>
            )}
            <Layout.Card style={{ backgroundColor: colors.main }}>
              <Layout.VerticalFlex>
                <h4 style={{ margin: 0 }}>Medlemmer</h4>
                {accessGranted.map((item, key) => <OrganizationMemberRow key={key} index={key} user={item} orgId={organizationId || ''} />)}
                {accessGranted.length === 0 && <span style={{ fontSize: 12, textAlign: "center" }}>Ingen medlemmer</span>}

              </Layout.VerticalFlex>
            </Layout.Card>
            <Layout.Card>
              <Layout.VerticalFlex>
                <h4 style={{ margin: 0 }}>Kontakt</h4>
                <p>Kontakt gogo for at ændre organisationens produkt</p>
                <a target='_blank' href='https://www.gogoo.app/kontakt'>Kontaktformular</a>
              </Layout.VerticalFlex>
            </Layout.Card>
          </Layout.VerticalFlex>
          <Layout.VerticalFlex style={{ flex: 2 }}>

            <Layout.Card>
              <Layout.HorizontalFlex style={{ justifyContent: 'space-between' }}>
                <Layout.VerticalFlex>
                  <h3>Søgefunktioner</h3>
                  <span>Opret en eller flere søgefunktioner til at hente eventdata.</span>
                </Layout.VerticalFlex>
                <Layout.VerticalFlex>
                  <button onClick={() => window.location.href = "/organisations/" + organizationId + "/feeds/create"}>Opret ny søgning</button>
                </Layout.VerticalFlex>
              </Layout.HorizontalFlex>
              <CustomTable metrics={[{ label: "Antal søgninger", filterFunction: (d) => d?.length || '0' }]}
                data={[["Feed navn", "Rediger"], ...organization.feeds.map((i) => ([i.feedName, <a href={"/organisations/" + organizationId + "/feeds/" + i.feedId}><div style={{ flex: 1, backgroundColor: colors.white, color: colors.main, padding: 3 }}>Åben</div></a>]))]}
                exportFileName={organizationId + "-feeds"} />
            </Layout.Card>
            <Layout.Card>
              <Layout.HorizontalFlex style={{ justifyContent: 'space-between' }}>
                <Layout.VerticalFlex>
                  <h3>Blogs</h3>
                  <span>Opret en eller flere artikler, som du kan bruge som blogs på organisationssiden.</span>
                </Layout.VerticalFlex>
                <Layout.VerticalFlex>
                  <button onClick={() => window.location.href = "/organisations/" + organizationId + "/blogs/create"}>Opret ny blog</button>
                </Layout.VerticalFlex>
              </Layout.HorizontalFlex>
              <CustomTable metrics={[{ label: "Antal blogindlæg", filterFunction: (d) => d?.length || '0' }]}
                data={[["Blog navn", "Rediger"], ...blogPosts.map((i) => ([i.name, <a href={"/organisations/" + organizationId + "/blogs/" + i.id}><div style={{ flex: 1, backgroundColor: colors.white, color: colors.main, padding: 3 }}>Åben</div></a>]))]}
                exportFileName={organizationId + "-blogs"} />
            </Layout.Card>
            <Layout.Card>
              <Layout.VerticalFlex>
                <h3>Tilsult lokationer</h3>
                <span>Tilslut en eller flere lokationer til din organisationsside. Derefter vil din organisation blive vist på de forskellige lokationers events og sider.</span>
              </Layout.VerticalFlex>
              <CustomTable
                data={[["Lokation", "Title", "Rediger"], ...venues.map((i) => ([i.Name,
                (organization.associatedVenues?.find((venue) => venue.venueId === i.Id)?.title || "Ukendt"),
                <div onClick={() => handleDeleteConnection(i.Id)} style={{ flex: 1, backgroundColor: colors.white, color: colors.main, padding: 3, cursor: 'pointer' }}>Slet tilslutning</div>]))]}
                exportFileName={organizationId + "-connected-venues"} />
              <Layout.VerticalFlex style={{ marginTop: 12 }}>
                <CreateVenueOrganisation organisation={organization} reload={getData} />
              </Layout.VerticalFlex>
            </Layout.Card>
            <Layout.Card>
              <Layout.HorizontalFlex style={{ justifyContent: 'space-between' }}>
                <Layout.VerticalFlex>
                  <h3>Banners</h3>
                  <span>Banner er dine visninger i appen. Du kan ikke ændre på området eller datoerne, medmindre du er administrator for gogo.</span>
                </Layout.VerticalFlex>
                <Layout.VerticalFlex>
                  <button onClick={() => window.location.href = "/organisations/" + organizationId + "/banners/create"}>Opret nyt banner</button>
                </Layout.VerticalFlex>
              </Layout.HorizontalFlex>
              <CustomTable metrics={[{ label: "Antal banners", filterFunction: (d) => d?.length || '0' }]}
                data={[["Banner navn navn", "Rediger"], ...banners.map((i) => ([i.title, <a href={"/organisations/" + organizationId + "/banners/" + i.id}><div style={{ flex: 1, backgroundColor: colors.white, color: colors.main, padding: 3 }}>Åben</div></a>]))]}
                exportFileName={organizationId + "-banners"} />
            </Layout.Card>
          </Layout.VerticalFlex>
        </Layout.HorizontalFlex>
      </Layout.Container>
    </Layout.Body>
  )
}