import * as React from 'react';
import { LoadingScreen } from '../../components/atoms/Defaults';
import RequestList, { AccessVariant, RequestItem } from '../../components/molecules/RequestList';
import { Navigation } from '../../components/organisms/Navigation';
import Layout from '../../components/templates/Layout';
import hooks from '../../globals/hooks';
import { Loading, LoadingState } from '../../globals/states';
import SearchVenueList from '../../components/molecules/SearchVenueList';
import { useNavigate } from 'react-router-dom';
import apiHooksV2 from '../../globals/api-hooks-v2';
import { KultunautVenue } from 'gogoo-common/content/Kultunaut';
import { ErrorScreenTemplate } from '../../components/templates/ErrorScreen';


const venueToRequestItem = (venue: KultunautVenue): RequestItem => {
  return {
    id: venue.Id.toString(),
    name: venue.Name,
    status: (venue.PremiumMetaData && venue.PremiumMetaData.isValid) ? "Premium" : "Not Premium"
  };
}

const sortRequestItem = (a: RequestItem, b: RequestItem): number => a.name.toUpperCase().localeCompare(b.name.toUpperCase())



export const VenuesIndex: React.FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState<LoadingState>()
  const [grantedAccess, setGrantedAccess] = React.useState<KultunautVenue[]>([])
  const [requestedAccess, setRequestedAccess] = React.useState<KultunautVenue[]>([])
  const [searchTerm, setSearchTerm] = React.useState<string>("")

  const init = async () => {
    setLoading({ loading: Loading.LOADING })
    const dashboard = await apiHooksV2.listUserVenues()
    if (dashboard) {
      setGrantedAccess(dashboard.grantedAccess)
      setRequestedAccess(dashboard.requestedAccess)
      setLoading({ loading: Loading.COMPLETE })
    } else {
      setLoading({ loading: Loading.ERROR, errorMessage: "Could not load admin profile" })
    }
  }

  const handleVenueRequest = async (venueId: number) => {
    setLoading({ loading: Loading.LOADING })
    const complete = await hooks.requestAccessToVenue(venueId)
    if (complete) {
      await init()
      setLoading({ loading: Loading.COMPLETE })
    } else {
      setLoading({ loading: Loading.ERROR, errorMessage: "Could not complete the request" })
    }
  }

  React.useEffect(() => { init() }, [])

  if (!loading || loading.loading === Loading.LOADING) return <LoadingScreen />
  if (loading.loading === Loading.ERROR) return <ErrorScreenTemplate><p>Error: {loading.errorMessage}</p></ErrorScreenTemplate>
  return (
    <Layout.Body>
      <Navigation />
      <Layout.Container>
        <Layout.VerticalFlex>
          <h2>Dine steder</h2>
          <input placeholder='Søg dine steder' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value || '')} style={{ marginBottom: 8 }} />
          <RequestList availableItems={(grantedAccess || []).map(venueToRequestItem).sort(sortRequestItem).filter((i)=> !searchTerm || i.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))} variant={AccessVariant.ACCESS_GRANTED} onClick={async (item) => navigate("/venues/" + item.id)} />
        </Layout.VerticalFlex>
        <Layout.VerticalFlex>
          <p>Dine anmodninger</p>
          <RequestList availableItems={(requestedAccess || []).map(venueToRequestItem).sort(sortRequestItem)}  variant={AccessVariant.REQUEST_SEND} onClick={async () => console.log("")} />
        </Layout.VerticalFlex>
        <Layout.VerticalFlex>
          <p>Søg efter sted og anmod om adgang</p>
          <SearchVenueList  onClickLabel='Anmod om adgang' excludedVenues={[...requestedAccess?.map((i)=> i.Id), ...grantedAccess?.map((i)=> i.Id)]} onVenueClick={handleVenueRequest} />
        </Layout.VerticalFlex>
      </Layout.Container>
    </Layout.Body>

  );
}