import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { colors } from '../../globals/colors';
import hooks from '../../globals/hooks';
import Layout from '../templates/Layout';
import apiHooksV2 from '../../globals/api-hooks-v2';

const MaxSize = 2000000
const HTMLEditor: React.FC<{ onChange: (value: string) => void, value?: string, hideKTNInput?: boolean }> = ({ onChange, value, hideKTNInput }) => {
    const [state, setState] = React.useState(value)
    const ref = React.useRef<any>();
    const [error, setError] = React.useState<string>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [eventId, setEventById] = React.useState<string>();

    React.useEffect(() => {
        if (state) onChange(state)
        //@ts-ignore
    }, [state, onChange])

    const handleClick = () => {
        if (ref && ref.current && ref.current.click) ref.current.click()
    }

    const handleChange = async (files: FileList | null) => {
        setError(undefined)
        if (!files || files.length === 0 || !files[0]) return setError("Vælge venligst en fil")
        const file = files[0]
        if (file.size > MaxSize) return setError("Filen må max være 2 mb")
        setLoading(true)
        const result = await apiHooksV2.uploadFile(file)
        if (!result) setError("Der skete en fejl")
        else setState(state + "<img src='" + result + "'/>")
        setLoading(false)
    }

    const insertKultuanutEvent = async () => {
        setError(undefined)
        if (!eventId || isNaN(Number(eventId))) return setError("Ugyldig event id")
        setLoading(true)
        const event = await hooks.getEvent(Number(eventId))
        if (!event) {
            setLoading(false)
            setError("Kunne ikke finde event med id: " + eventId)
        }
        let html = `
        <a href="https://udbyder.gogoo.app/perl/arrmore/type-gogoo?ArrNr=`+ event.Id + `">
            <img src="`+ event.Image + `"/><br/>
            <h1>`+ event.Title + `</h1>
            <span>`+ event.Startdate + " - " + event.Enddate + " " + event.Time + `</span>
        </a>
        `
        setLoading(false)
        setEventById(undefined)
        setState(state + "" + html)
    }

    const modules = React.useMemo(() => ({
        toolbar: {
            container: [
                [{ 'header': 1 }, { 'header': 2 }, "bold", 'italic', 'underline'],
                ["image", "link"],
            ],
            handlers: {
                image: handleClick,
            },
        },
    }), []);


    return (
        <>
            {!hideKTNInput && (<Layout.VerticalFlex style={{ marginBottom: 10, marginTop: 10 }}>
                <span>Indsæt kultunaut event</span>
                <Layout.HorizontalFlex>
                    <input onChange={(e) => setEventById(e.target.value)} value={eventId} />
                    <button onClick={insertKultuanutEvent}>Indsæt</button>
                </Layout.HorizontalFlex>
            </Layout.VerticalFlex>)}

            {loading && <span>Loading</span>}
            {error && <span>{error}</span>}
            <div style={{ backgroundColor: colors.white }}>
                <input ref={ref} type="file" accept="image/*" onChange={(e) => handleChange(e.target.files)} style={{ display: 'none' }} />
                <ReactQuill theme="snow" value={state} onChange={setState} modules={modules} />
            </div>
        </>
    )

}

export default HTMLEditor