import * as React from 'react';
import styled from 'styled-components';
import Input, { KeyDisplayNamePair } from '../atoms/Input';
import Layout from './Layout';
import { colors } from '../../globals/colors';
import { KultunautEvent } from '../../../../gogoo-common/content/Kultunaut'
import Spinner from '../atoms/LoadingSpinner';
import { Accordion } from '../atoms/Accordion';
import { LargeEventView } from '../atoms/AppComponents';
import { Feed } from 'gogoo-common/content/Organisation';
import apiHooksV2 from '../../globals/api-hooks-v2';
import EventTags from '../../globals/event-tags';



export const TagRoot = styled.span<{ checked: boolean }>`
    margin: 3px 6px;
    padding: 3px 12px;
    background-color: ${(props) => props.checked ? colors.white : colors.main};
    color: ${(props) => !props.checked ? colors.white : colors.main};
    font-size: 11px;
    cursor: pointer;
    &:hover{
      color: ${colors.main};
      background-color: ${colors.white};
    }
`;

const EventTag: React.FC<{ eventTag: string, prechecked: boolean, handleClick(item: string, add: boolean): void }> = ({ eventTag, prechecked, handleClick }) => {
  const [checked, setChecked] = React.useState(prechecked)
  React.useEffect(() => {
    setChecked(prechecked)
  }, [prechecked])

  const handleChecked = () => {
    const newState = !checked
    setChecked(newState)
    handleClick(eventTag, newState)
  }

  return (
    <TagRoot onClick={handleChecked} checked={checked}>{eventTag}</TagRoot>
  )
}

const EventTagView: React.FC<{ eventTags: string[], selected: string[], setter(state: string[]): void, title: React.ReactNode, selectAll: React.ReactNode }> = ({ eventTags, selected, setter, title, selectAll }) => {
  const handleClick = (item: string, add: boolean) => {
    if (add) setter([...selected, item])
    else setter(selected.filter((i) => i !== item))
  }
  return (
    <Layout.VerticalFlex>
      <Layout.HorizontalFlex style={{ flexWrap: 'wrap' }}>
        <Accordion title={title} children={

          <div style={{ width: 550, display: "flex", flexWrap: "wrap" }}>
            {selectAll}
            {eventTags.map((i, key) => <EventTag key={key} eventTag={i} prechecked={selected.includes(i)} handleClick={handleClick} />)
            }</div>} />

      </Layout.HorizontalFlex>
    </Layout.VerticalFlex>
  )
}

const InputLabel = styled.span`
    margin-top: 10px;
    margin-bottom: 4px;
    font-size: 11px;
`;

enum FeedMethodTypes {
  EventList = "EventList",
  EventAddressId = "EventAddressId",
  EventId = "EventId"
}
const feedMethods: KeyDisplayNamePair[] = [
  { key: FeedMethodTypes.EventList, displayName: "Liste med postnumre" },
  { key: FeedMethodTypes.EventAddressId, displayName: "Udbyder, sortert på dato" },
  { key: FeedMethodTypes.EventId, displayName: "Event id, sortert på indtastet rækkefølge" },
]
const RNBName = "Musik:Funk/blues/R&B"
const RNBIntp = "Musik:Funk/blues/R and B"

export const FeedTemplate: React.FC<{ onSubmit(feedName: string, feedString: string, feedType?: string): Promise<void>, feedData?: Feed }> = ({ onSubmit, feedData }) => {


  const [loadingEvents, setLoadingEvents] = React.useState(true)
  const [eventError, setEventError] = React.useState<string>()
  const [events, setEvents] = React.useState<KultunautEvent[]>([])
  const [eventsTags, setEventsTags] = React.useState<string[]>([])


  const [searchMethod, setSearchMethod] = React.useState<KeyDisplayNamePair>(feedMethods[0])
  let searchString = searchMethod.key.toString()
  const nexPrefix = (val: string) => val.includes("?") ? "&" : "?"

  const [feedName, setFeedName] = React.useState<string>("")

  const [zipCodes, setZipCodes] = React.useState<string>("")
  const useZipCodes = searchMethod.key === FeedMethodTypes.EventList
  if (useZipCodes && zipCodes) searchString += nexPrefix(searchString) + "zipcodes=" + zipCodes

  const [venueId, setVenueId] = React.useState<string>("")
  const useVenueId = searchMethod.key === FeedMethodTypes.EventAddressId
  if (venueId !== "" && useVenueId) searchString += nexPrefix(searchString) + "Id=" + venueId

  const [eventIds, setEventIds] = React.useState<string>("")
  const useEventIds = searchMethod.key === FeedMethodTypes.EventId
  if (eventIds !== "" && useEventIds) searchString += nexPrefix(searchString) + "Id=" + eventIds

  const [tags, setTags] = React.useState<string[]>([])
  if (tags.length > 0) searchString += nexPrefix(searchString) + "tags=" + tags.join('|')

  const [extras, setExtras] = React.useState<string>("")
  if (extras !== "" && !searchString.includes(extras)) searchString += nexPrefix(searchString) + extras



  const decodeSearchString = (input: string) => {
    try {
      const encodedSearch = input.split(RNBName).join(RNBIntp)
      if (encodedSearch.includes(FeedMethodTypes.EventList)) setSearchMethod(feedMethods[0])
      if (encodedSearch.includes(FeedMethodTypes.EventAddressId)) setSearchMethod(feedMethods[1])
      if (encodedSearch.includes(FeedMethodTypes.EventId)) setSearchMethod(feedMethods[2])

      const params = encodedSearch.split("?") && encodedSearch.split("?")[1]
      if (params) {
        const options = params.split('&')
        console.log(options)
        options.forEach((item) => {
          if (item && item.split('=')) {
            const key = item.split('=')[0]
            const value = item.split('=')[1]
            if (value) {
              if (key === "tags") setTags(value.split('|'))
              else if (key === "zipcodes") setZipCodes(value)
              else if (key === "Id") {
                if (encodedSearch.includes(FeedMethodTypes.EventAddressId)) setVenueId(value)
                if (encodedSearch.includes(FeedMethodTypes.EventId)) setEventIds(value)
              }
              else setExtras(item)
            }
          }
        })
      }

    } catch (error) {
      console.log(error)
    }
  }

  const init = React.useCallback(async () => {
    setEventsTags(EventTags.map((i) => i === RNBName ? RNBIntp : i))
    if (feedData) {
      setFeedName(feedData.feedName)
      decodeSearchString(feedData.feedString)
    }
  }, [feedData])

  React.useEffect(() => { init() }, [feedData, init])

  const getEvents = async (search: string) => {
    setLoadingEvents(true)
    const searchString = search.split(RNBIntp).join(RNBName)
    const result = await apiHooksV2.searchEvent({ customFeedSearch: searchString })

    if (result) {
      setEventError(undefined)
      setEvents(result)
    } else {
      setEventError("Events ikke fundet")
      setEvents([])
    }
    setLoadingEvents(false)
  }


  React.useEffect(() => {
    getEvents(searchString)
  }, [searchString])



  const isAlLEventTagsSelected = eventsTags.length === tags.length
  const onSelectAllEventTags = () => isAlLEventTagsSelected ? setTags([]) : setTags(eventsTags)
  return (
    <Layout.VerticalFlex>
      <Layout.HorizontalFlexJustifyCenter style={{ height: 50 }}>{loadingEvents && <Spinner />}</Layout.HorizontalFlexJustifyCenter>
      <Layout.HorizontalFlex >
        {eventError &&
          <span style={{ backgroundColor: colors.danger, color: colors.white }}>
            {eventError}
          </span>}
        <Layout.VerticalFlex style={{ width: "53%" }}>
          <InputLabel>Feed Name</InputLabel>
          <input required onChange={(e) => setFeedName(e.target.value)} value={feedName} />
          <InputLabel>Vælge søge metode</InputLabel>
          <Input.Select items={feedMethods} onSelect={setSearchMethod} selected={searchMethod} />

          {useZipCodes &&
            <>
              <InputLabel>Postnumre (adskilt af komma)</InputLabel>
              <input onChange={(e) => setZipCodes(e.target.value)} value={zipCodes} />
            </>}

          {useVenueId &&
            <>
              <InputLabel>Udbyder id</InputLabel>
              <input onChange={(e) => setVenueId(e.target.value)} value={venueId} />
            </>}
          {useEventIds &&
            <>
              <InputLabel>Event id'er (Kommasepareret)</InputLabel>
              <input onChange={(e) => setEventIds(e.target.value)} value={eventIds} />
            </>}
          {!useEventIds && eventsTags && <EventTagView title={<Layout.HorizontalFlex>
            <InputLabel>Event tags</InputLabel>
          </Layout.HorizontalFlex>} eventTags={eventsTags} selected={tags} setter={setTags}
            selectAll={<TagRoot onClick={onSelectAllEventTags} checked={isAlLEventTagsSelected}>{isAlLEventTagsSelected ? "Fravælg alle" : "Vælge alle"}</TagRoot>} />}

          <InputLabel>Tilføj ekstra</InputLabel>
          <input onChange={(e) => setExtras(e.target.value)} value={extras} />

          <button style={{ marginTop: 32 }} className='secondary' onClick={() => getEvents(searchString)}>Opdater</button>

          <button disabled={!feedName || feedName.length === 0} style={{ marginTop: 32 }} onClick={() => onSubmit(feedName, searchString)}>Gem ændringer</button>

        </Layout.VerticalFlex>

        <Layout.VerticalFlex style={{ paddingLeft: 20 }}>
          <Layout.VerticalFlex style={{ width: 450, paddingLeft: 20, height: 600, overflowY: 'scroll' }}>
            {loadingEvents && <span style={{ justifyContent: 'center' }}>Henter events</span>}
            {events.map((item, key) => {
              return <a key={key} style={{ textDecoration: 'none' }} target="_blank" href={"https://www.kultunaut.dk/perl/arrmore/type-nynaut?ArrNr=" + item.Id} rel="noreferrer" ><LargeEventView eventId={item.Id} preloadedEvent={item} showSmall /></a>
            })}
            {!loadingEvents && events.length === 0 && <span>Ingen events</span>}
          </Layout.VerticalFlex>
        </Layout.VerticalFlex>
      </Layout.HorizontalFlex>
    </Layout.VerticalFlex>

  );
}