import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingScreen } from '../../components/atoms/Defaults';
import { Navigation } from '../../components/organisms/Navigation';
import Layout from '../../components/templates/Layout';
import { Loading, LoadingState } from '../../globals/states';
import apiHooksV2 from '../../globals/api-hooks-v2';
import { Organisation } from 'gogoo-common/content/Organisation';
import { BreadCrumb } from '../../components/molecules/BreadCrumb';
import { BlogPostEditor } from '../../components/organisms/EditBlogPost';
import { CreateBlogPost } from 'gogoo-common/admin/BlogPost';
import { BlogPost } from 'gogoo-common/content/BlogPost';
import { ErrorScreenTemplate } from '../../components/templates/ErrorScreen';


export const OrganisationBlogPage: React.FC<{ create?: boolean }> = ({ create }) => {
  const navigate = useNavigate()
  const { organizationId, blogId } = useParams<{ organizationId: string, blogId?: string }>()
  const [organization, setOrganization] = React.useState<Organisation>()
  const [loading, setLoading] = React.useState<LoadingState>()
  const [blogPost, setBlogPost] = React.useState<BlogPost>()

  const init = React.useCallback(async () => {
    setLoading({ loading: Loading.LOADING })
    const result = organizationId && await apiHooksV2.getOrgansationsDashboard(organizationId)
    if (result) {
      setOrganization(result.organisation)
      const existing = !!blogId && result.blogPosts.find((i) => i.id === blogId)
      if (!!existing && !create) {
        const local = await apiHooksV2.getBlogPostById(existing.id)
        setBlogPost(local)
      }
      setLoading({ loading: Loading.COMPLETE })
    } else {
      setLoading({ loading: Loading.ERROR, errorMessage: "Kunne ikke finde organisationen med id: " + organizationId })
    }
  }, [organizationId, blogId])

  const onCreate = async (create: CreateBlogPost) => {
    const result = await apiHooksV2.createBlogPost(create)
    if (result) {
      navigate("/organisations/" + result.organizationOwner + "/blogs/" + result.id)
    } else {
      setLoading({ loading: Loading.ERROR, errorMessage: "Der skete en fejl i kommunikationene til serveren" })
    }
  }

  const onUpdate = async (updates: BlogPost) => {
    const result = await apiHooksV2.updateBlogPost(updates)
    if (result) {
      navigate("/organisations/" + result.organizationOwner)
      setLoading({ loading: Loading.COMPLETE })
    } else {
      setLoading({ loading: Loading.ERROR, errorMessage: "Der skete en fejl i kommunikationene til serveren" })
    }
  }

  React.useEffect(() => { init() }, [init])

  if (!loading || loading.loading === Loading.LOADING) return <LoadingScreen />
  if (loading.loading === Loading.ERROR) return <ErrorScreenTemplate><p>Error: {loading.errorMessage}</p></ErrorScreenTemplate>
  if (!organization) return <ErrorScreenTemplate><p>Error: Contact IT Admin</p></ErrorScreenTemplate>


  return (
    <Layout.Body>
      <Navigation title={organization?.name} />
      <Layout.Container>
        <Layout.HorizontalFlex style={{ justifyContent: 'space-between', marginBottom: 24, alignItems: 'center' }}>
          <BreadCrumb links={[
            { label: "Organisationer", url: "/organisations" },
            { label: organization.name, url: "/organisations/" + organization.organizationId },
            { label: "Blogs", url: "/organisations/" + organization.organizationId },
            { label: blogPost?.name || "Ny blog", url: "/organisations/" + organization.organizationId + "/blog/" + (blogId || "create") },
          ]} />
        </Layout.HorizontalFlex>
      </Layout.Container>
      <Layout.Container>
        {!create && blogPost && <BlogPostEditor initial={blogPost} organizationId={organization.organizationId} onUpdate={onUpdate} />}
        {create && <BlogPostEditor organizationId={organization.organizationId} onCreate={onCreate} />}
      </Layout.Container>
    </Layout.Body>
  )
}