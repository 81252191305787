import * as React from 'react';
import Layout from '../templates/Layout';
import { DataBox } from './DataElements';
import { CSVLink } from "react-csv";
import { colors } from '../../globals/colors';

export type Metric = {
  label: string,
  filterFunction(data?: string[][]): number | string
}

const CustomTable: React.FC<{ data?: (string | JSX.Element)[][], exportFileName?: string, tableHeading?: string | React.ReactNode, metrics?: Metric[] }> = ({ data, exportFileName, tableHeading, metrics }) => {
  const [isEmpty, setIsEmpty] = React.useState(true)
  const [page, setPage] = React.useState<number>(1)
  const [head, setHead] = React.useState<(string | JSX.Element)[]>()
  const [body, setBody] = React.useState<(string | JSX.Element)[][]>()


  const PAGE_SIZE = 10


  React.useEffect(() => {
    if (data && data[0] && data.length > 1) {
      setIsEmpty(false)

      setHead(data[0])
      setBody(data.slice(1))
    } else {
      setIsEmpty(true)
    }
  }, [data])

  if (!data || isEmpty) return (
    <Layout.VerticalFlex style={{ width: '100%' }}>
      <Layout.HorizontalFlex style={{ justifyContent: "space-between", alignItems: 'center' }}>
        <h2>{tableHeading}</h2>
      </Layout.HorizontalFlex>
      <Layout.VerticalFlex style={{ width: '100%', height: 100, backgroundColor: colors.lightMain, justifyContent: 'center', borderTop: "60px solid", borderTopColor: colors.primary }}>
        <span style={{ textAlign: 'center' }}>Tabellen er tom</span>
      </Layout.VerticalFlex>
    </Layout.VerticalFlex>
  )

  if (!body || !head) return <></>

  const startIndex = (page - 1) * PAGE_SIZE
  const endIndex = startIndex + PAGE_SIZE
  const result = body.slice(startIndex, endIndex)

  const totalPages = Math.ceil((data.length - 1) / PAGE_SIZE)
  const BACK_ACTIVE = page !== 1
  const NEXT_ACTIVE = totalPages > 0 && data && page !== totalPages

  return (
    <Layout.VerticalFlex style={{ width: '100%' }}>
      <Layout.HorizontalFlex style={{ justifyContent: "space-between", alignItems: 'center' }}>
        <h2>{tableHeading}</h2>
      </Layout.HorizontalFlex>
      {metrics && <Layout.HorizontalFlex style={{ alignItems: 'center', gap: 20 }}>
        {metrics.map((item, key) => <DataBox key={key} label={item.label} data={item.filterFunction(data.map((i) => i.map((l) => (l || '').toString())))} />)}
      </Layout.HorizontalFlex>}
      {exportFileName && <Layout.HorizontalFlex style={{ flexDirection: 'row-reverse', alignItems: 'center', marginBottom: 12 }}>
        <CSVLink filename={exportFileName.split(' ').join('-') + '.csv'} separator={";"} headers={head.map((i) => (i || '').toString())} data={body}><button>Download data</button></CSVLink>
      </Layout.HorizontalFlex>}
      <table>
        <thead>
          <tr>
            <th style={{ fontSize: 13, width: '50%' }} >{head[0]}</th>
            {head.map((cell, key) =>key === 0 ? undefined: <th style={{ fontSize: 13 }} key={key}>{cell}</th>)}
          </tr>
        </thead>
        <tbody>
          {result.map((row, outerKey) => (
            <tr key={outerKey}>
              {row.map((cell, key) => <td style={{ fontSize: 11 }} key={key}>{cell}</td>)}
            </tr>
          ))}
        </tbody>
      </table>
      <Layout.HorizontalFlex style={{ justifyContent: "space-between", alignItems: 'center' }}>
        <div onClick={() => BACK_ACTIVE && setPage(page - 1)} style={!BACK_ACTIVE ? { backgroundColor: colors.lightMain, color: colors.main, cursor: 'auto' } : {}} className='tableButton'>Forrig side</div>
        <span>{page} of {totalPages > 0 ? totalPages : 1}</span>
        <div onClick={() => NEXT_ACTIVE && setPage(page + 1)} style={!NEXT_ACTIVE ? { backgroundColor: colors.lightMain, color: colors.main, cursor: 'auto' } : {}} className='tableButton'>Næste side</div>
      </Layout.HorizontalFlex>
    </Layout.VerticalFlex>
  )
}

export default CustomTable