export const colors = {
    white: '#fff',
    main: '#333',
    lightMain: '#4d4d4d',
    background: '#141414',
    primary: '#0D5A5B',
    danger: '#ff4242',
    blog: "#1A40CC"
}

