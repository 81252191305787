import * as React from 'react';
import styled from 'styled-components';
import { colors as GlobalColors } from '../../globals/colors';
import hooks from '../../globals/hooks';
import { Loading, LoadingState } from '../../globals/states';
import { KultunautEvent } from '../../../../gogoo-common/content/Kultunaut'
import Layout from '../templates/Layout';
import { ReactChildren } from '../../globals/helpers';

const colors = {
  mainBlack: GlobalColors.primary,
  mainWhite: GlobalColors.white,
  blogColor: GlobalColors.blog
}

const BiggestFontSize = 22
const DefaultFontSize = 16
const SmallFontSize = 12
export const AppWidth = 450




//24px
export const RegularHeading = styled.span`
    font-size: ${BiggestFontSize}px;
    color: ${colors.mainBlack};
    margin-bottom: 10px;
    font-family: "Roboto Condensed", sans-serif;
    padding: 0px;
    margin-top: 0px;
`

//16px
const RegularText = styled.p`
    font-size: ${DefaultFontSize}px;
    font-family: "Roboto Condensed", sans-serif;
    padding: 0px;
    line-height: 17.07px;
    margin-top: 4px;
    color: ${colors.mainBlack};
    margin-bottom: 4px;
`

//12px
const SmallText = styled.span`
    font-size: ${SmallFontSize}px;
    font-family: "Roboto Condensed", sans-serif;
    color: ${colors.mainBlack};
`

export const RootHorizontalItem: React.FC<ReactChildren & { image: string }> = ({ children, image }) => {

  return (
    <div style={{ height: 150 }}>
      <div style={{ display: 'flex', flex: 5, }}>
        <div style={{ display: 'flex', flexDirection: 'row', height: 150, width: '100%' }}>
          <div style={{ flex: 1, backgroundImage: `url(${image})`, backgroundSize: 'cover', height: 150, width: 100 }} />
          <div style={{
            backgroundColor: colors.mainWhite, borderBottomRightRadius: 40,
            flex: 3, padding: 20
          }}>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}



export const LargeEventView: React.FC<{ eventId?: number | string, preloadedEvent?: KultunautEvent; showSmall?: boolean; previewColor?: string; forUICreator?: boolean, setPreloadedEvents?: (event: KultunautEvent) => void }> = ({ eventId, preloadedEvent, showSmall = false, previewColor, forUICreator = false, setPreloadedEvents }) => {
  const [event, setEvent] = React.useState<KultunautEvent>()
  const [loading, setLoading] = React.useState<LoadingState>()
  React.useEffect(() => {
    const get = async (id: number) => {
      setLoading({ loading: Loading.LOADING })
      const result = await hooks.getEvent(id)
      if (result) {
        setLoading({ loading: Loading.COMPLETE })
        setEvent(result)
        if (setPreloadedEvents) setPreloadedEvents(result)
      } else {
        setLoading({ loading: Loading.ERROR, errorMessage: "This event is invalid" })
      }
    }

    if (!eventId && !preloadedEvent) setLoading({ loading: Loading.ERROR, errorMessage: "Provide event or eventid" })
    else if (eventId) {

      if (isNaN(Number(eventId))) setLoading({ loading: Loading.ERROR, errorMessage: "Event id must be a number" })
      else {
        if (preloadedEvent) {
          setLoading({ loading: Loading.COMPLETE })
          setEvent(preloadedEvent)
        }
        else get(Number(eventId))
      }
    }

    else setEvent(preloadedEvent)


  }, [eventId, preloadedEvent, setPreloadedEvents])

  if (!loading || loading.loading === Loading.LOADING) return <p style={{ color: colors.mainBlack, padding: 10 }}>Loading event</p>
  if (loading.loading === Loading.ERROR) return <p style={{ color: colors.mainBlack, padding: 10 }}>Error: {loading.errorMessage}</p>
  if (!event) return <p style={{ color: colors.mainBlack, padding: 10 }}>Error: Contact IT Admin</p>

  const dateString = "Date" //getDateRangeString(event)
  const image = event.Image
  const title = event.Title
  const tags = event.Tags
  const organizerName = event.OrganizerName || event.LocationName
  const location = event.OrganizerName ? event.LocationName : event.LocationCity

  return (
    <div
      style={{ marginBottom: forUICreator ? 0 : 45 }}
    >
      <div style={{ height: '100%' }}>
        {showSmall ? (<RootHorizontalItem image={image || 'https://prod-attachments-bucket-gogoo-1.s3.eu-west-1.amazonaws.com/4fe05ab5-7507-4622-b027-62f3286aa5bb'}>
          <RegularHeading style={{ fontSize: 15 }}>
            {title}
          </RegularHeading><br />
          <RegularHeading style={{ fontSize: 10 }}>
            {dateString} {location}
          </RegularHeading>
          <Layout.VerticalFlex>
            {tags.map(tag => <span style={{ fontSize: 12, color: previewColor ?? colors.mainBlack }}>{tag}</span>)}
          </Layout.VerticalFlex>
        </RootHorizontalItem>) : (<>
          <img draggable={false} style={{ height: AppWidth * 0.60, width: AppWidth, marginBottom: -5 }} src={image} alt={""} />
          <div style={{
            paddingLeft: 30, paddingRight: 30, paddingBottom: 20, paddingTop: 20, borderBottomRightRadius: 30,
            backgroundColor: colors.mainWhite
          }}>
            <RegularHeading>{title}</RegularHeading>
            <RegularText>{organizerName} - {location}</RegularText>
            <Layout.HorizontalFlex>
              <Layout.VerticalFlex>
                <RegularText>{dateString}</RegularText>
                <RegularText style={{ color: previewColor ?? colors.mainBlack }}>{tags.join(", ")}</RegularText>
              </Layout.VerticalFlex>
            </Layout.HorizontalFlex>
          </div>
        </>)}
      </div>
    </div>
  )
}

export const FeedBannerItem: React.FC<{ image: string, title: string, description: string }> = ({ image, title, description }) => {

  return (
    <div style={{ width: '100%', minWidth: 400 }}>
      <RootHorizontalItem image={image}>
        <Layout.VerticalFlex>
          <RegularText style={{ marginBottom: 0 }}>{title}</RegularText>
          <SmallText>{description}</SmallText>
        </Layout.VerticalFlex>
      </RootHorizontalItem>
    </div>
  )
}


export const BlogPostPreview: React.FC<{ image?: string, title?: string, description?: string, blogPostTag?: string }> = ({ image, title, description, blogPostTag }) => {

  return (
    <div style={{ borderRadius: 16, maxWidth: 300 }}>
      <div style={{
        backgroundImage: `url(${image})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        borderRadius: 16,
      }}>
        <Layout.VerticalFlex style={{
          minHeight: 300,
          minWidth: 250,
          background: `linear-gradient(rgba(255,255,255,0), ${colors.blogColor})`,
          textAlign: 'center',
          justifyContent:'space-between',
          padding: 12,
          borderRadius: 16
        }}>
          <div>
          <span style={{backgroundColor: colors.blogColor, padding: '6px 24px', borderRadius: 30}}>{blogPostTag}</span>
          </div>
          <Layout.VerticalFlex style={{
            color: 'white',
          }}>
            <RegularHeading style={{ color: 'white', fontSize: 20 }}>{title}</RegularHeading>
            <SmallText style={{ color: 'white', fontSize: 12 }}>{description}</SmallText>
          </Layout.VerticalFlex>
        </Layout.VerticalFlex>
      </div>
    </div>
  )
}
