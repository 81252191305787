import * as React from 'react';
import Layout from '../templates/Layout';
import SearchVenueList from './SearchVenueList';
import { Organisation } from 'gogoo-common/content/Organisation';
import apiHooksV2 from '../../globals/api-hooks-v2';




export const CreateVenueOrganisation: React.FC<{ organisation: Organisation, reload(): Promise<void> }> = ({ organisation, reload }) => {

  const [selectedVenue, setSelectedVenue] = React.useState<{ id: number, name: string } | undefined>(undefined)
  const [linkTitle, setLinkTitle] = React.useState<string>("")
  const [loading, setLoading] = React.useState<boolean>(false)

  const onSubmit = async () => {
    if(!linkTitle || !selectedVenue)  return
    setLoading(true)
    const associatedVenues = [{ venueId: selectedVenue.id, title: linkTitle }, ...(organisation.associatedVenues|| [])]
    await apiHooksV2.updateOrganisation({ ...organisation, associatedVenues })
    setLoading(false)
    await reload()
  }

  return (
    <Layout.VerticalFlex style={{gap: 10}}>
      <b>Tilslut ny lokation</b>
      {!selectedVenue && <SearchVenueList excludedVenues={organisation.associatedVenues?.map((i)=> i.venueId) || []} onVenueClick={(id, name) => setSelectedVenue({ id, name })} onClickLabel="Tilføj" />}
      {selectedVenue && <Layout.VerticalFlex style={{backgroundColor: organisation.colorScheme.backgroundColor, height: 40, padding: "5px 10px", borderRadius: 5}}>
        <span style={{color: organisation.colorScheme.textColor, fontSize: 10 }}>{organisation.name}</span>
        <span style={{color: organisation.colorScheme.textColor, fontSize: 16 }}>{linkTitle}</span>
      </Layout.VerticalFlex>
      }
      <input type="text" placeholder="Title på linket (Fx: Få det hele med)" onChange={(e) => setLinkTitle(e.target.value)} />
      {selectedVenue && <span style={{fontSize: 12 }}><b>Valgt lokation:</b> {selectedVenue.name} (Id: {selectedVenue.id})</span>}
      <button onClick={onSubmit} disabled={!linkTitle || !selectedVenue || loading}>{loading? "Opretter.." :"Opret tilslutning"}</button>
      {loading && <span>Loading...</span>}
    </Layout.VerticalFlex>
  )
}



