import * as React from 'react';
import Layout from '../templates/Layout';
import { GridLocation } from '../../shared/CMS';
import { GoogleMap, LoadScript, Rectangle } from '@react-google-maps/api';


const bounds = {
  north: 57.8366,
  south: 54.5068,
  east: 15.3847,
  west: 7.7470
}


const locationToBounds = (gridLocation: GridLocation) => {
  return ({ west: gridLocation.bottomLongitude, south: gridLocation.leftLatitude, north: gridLocation.rightLatitude, east: gridLocation.topLongitude })
}

const boundsToLocation = (bounds: { west: number, south: number, north: number, east: number }): GridLocation => {
  return ({ bottomLongitude: bounds.west, leftLatitude: bounds.south, rightLatitude: bounds.north, topLongitude: bounds.east })
}

export const defaultGridLocations: GridLocation = boundsToLocation(bounds)


export const DrawableMap: React.FC<{ onComplete(location: GridLocation): void, location?: GridLocation }> = ({ onComplete, location }) => {
  const [hasBeenEdited, setHasBeenEdited] = React.useState(false)

  const mapRef = React.useRef<any>()

  const getLocation = () => {
    const location = mapRef?.current?.state?.rectangle?.bounds
    if (!location) return

    const returnableObject = {
      east: 0,
      west: 0,
      north: 0,
      south: 0,
    }

    const keys = Object.keys(location)
    const direction1hi = location[keys[0]].hi
    const direction1lo = location[keys[0]].lo

    const direction2hi = location[keys[1]].hi
    const direction2lo = location[keys[1]].lo
    console.log({direction1hi, direction1lo, direction2hi, direction2lo})

    if (direction1hi < direction2hi) {
      returnableObject.south = direction2lo
      returnableObject.north = direction2hi
      returnableObject.east = direction1hi
      returnableObject.west = direction1lo
    } else {
      returnableObject.south = direction1lo
      returnableObject.north = direction1hi
      returnableObject.east = direction2hi
      returnableObject.west = direction2lo
    }

    return boundsToLocation(returnableObject)
  }
  const handleComplete = () => {
    setHasBeenEdited(false)
    const locationResult = getLocation()
    if (locationResult) onComplete(locationResult)
  }

  return (
    <Layout.VerticalFlex style={{ marginTop: 32 }}>
      <LoadScript
        googleMapsApiKey="AIzaSyC-YYOACb-qAzUMJaRw_em-GUhEXWYXf1U"
        libraries={['drawing']}>
        <GoogleMap
          mapContainerStyle={{ width: '500px', height: '500px' }}
          center={{ lat: 56, lng: 11 }}
          zoom={6.5} >
          <Rectangle
            ref={mapRef}
            editable={true}
            draggable={true}
            onDragEnd={() => setHasBeenEdited(true)}
            onBoundsChanged={() => setHasBeenEdited(true)}
            onMouseUp={(e) => {
              console.log(e.latLng?.toJSON())
            }}
            bounds={location ? locationToBounds(location) : bounds}/>
        </GoogleMap>
      </LoadScript>
      {hasBeenEdited && <button style={{ marginTop: 12 }} onClick={handleComplete}>Gem ændringer</button>}
    </Layout.VerticalFlex>
  )
}

