import { DetailedEventData, DetailedEventTemplate, HTMLElmentData, HTMLElmentTemplate } from "../feed/feed-items";

type RichText = string;

export enum BlogPostTags {
    Experience = "Kulturoplevelse",
    Personalities = "Kulturpersonlighed",
    Guides = "Guide",
    FreeGuides = "Gratis guide",
    MonthlyGuides = "Månedsguide"
}

export interface BlogPostPreview {
    summary: string;
    name: string;
    shorttitle: string;
    slug: string;
    thumbnail: string;
    id: string;
    createdAt: number;
    publishDate: number;
    organizationOwner?: string;
    blogPostTag?: BlogPostTags;
    readTime?: string;
    template?: BlogTemplateItems[]
}

export interface BlogPost extends BlogPostPreview {
    content: RichText;
    templatedContent?: BlogTemplateData[]
}

export type BlogTemplateItems = DetailedEventTemplate | HTMLElmentTemplate

export type BlogTemplateData = DetailedEventData | HTMLElmentData